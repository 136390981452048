import { useParams } from 'react-router-dom'

import { Empty } from 'antd'

import Show from 'common/components/Show2/Show'
import OtherLeasesThumbnails from 'common/components/Thumbnail/OtherLeasesThumbnails'

import { useFetchPromotionRecurringQuery } from 'features/Promotions/state/api/promotionsApi'

const PromotionDetailsOthers = () => {
  const params = useParams()

  const leaseConnection = {
    leaseId: params?.leaseId,
    connection: params?.connection,
  }
  const { data, isLoading, isSuccess } = useFetchPromotionRecurringQuery(leaseConnection)
  return (
    <>
      <Show>
        <Show.When isVisible={!data?.length && isSuccess}>
          <Empty />
        </Show.When>
        <Show.Else>
          <OtherLeasesThumbnails leases={data} isLoading={isLoading} />
        </Show.Else>
      </Show>
    </>
  )
}

export default PromotionDetailsOthers
