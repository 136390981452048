import type { RouteProps } from 'react-router-dom'

import { AuthCompleted, AuthIdentity, AuthValidationStatus } from 'features/Auth'

enum UNCONFIRMED_ROUTES {
  VALIDATION_RESULT = 'VALIDATION_RESULT',
  START_PROCESSING = 'START_PROCESSING',
  VALIDATE_IDENTITY = 'VALIDATE_IDENTITY',
  VALIDATION_PROCESSING = 'VALIDATION_PROCESSING',
}

export const UNCONFIRMED_PATHS: Record<UNCONFIRMED_ROUTES, string> = {
  [UNCONFIRMED_ROUTES.VALIDATION_RESULT]: '/processing-result',
  [UNCONFIRMED_ROUTES.START_PROCESSING]: '/start-processing-documents',
  [UNCONFIRMED_ROUTES.VALIDATE_IDENTITY]: '/validate-doctor-credentials',
  [UNCONFIRMED_ROUTES.VALIDATION_PROCESSING]: '/status-processing-documents',
}

export const UNCONFIRMED_ROUTE_CONFIG: RouteProps[] = [
  {
    path: UNCONFIRMED_PATHS.START_PROCESSING,
    element: <AuthValidationStatus />,
  },
  {
    path: UNCONFIRMED_PATHS.VALIDATION_RESULT,
    element: <AuthCompleted />,
  },
  {
    path: UNCONFIRMED_PATHS.VALIDATE_IDENTITY,
    element: <AuthIdentity />,
  },
  {
    path: UNCONFIRMED_PATHS.VALIDATION_PROCESSING,
    element: <AuthValidationStatus />,
  },

  // {
  //   path: '*',
  //   element: <Navigate to={UNCONFIRMED_PATHS.VALIDATE_IDENTITY} replace />,
  // },
]
