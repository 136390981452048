import { Navigate, Outlet } from 'react-router-dom'

import { PATHS } from 'routes/paths'

import { useAuth } from 'app/providers'

export const UnauthorizedRoutes = () => {
  const { user } = useAuth()

  if (user) return <Navigate to={PATHS.HOME_ONGOING} />
  return <Outlet />
}
