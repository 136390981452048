import { useEffect, useState } from 'react'

const MOBILE_WIDTH_THRESHOLD = 580

// similar as in ANTD design
const breakpointsValues = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_THRESHOLD)

  const [breakpoints, setBreakpoints] = useState({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  })

  const handleResize = () => {
    const newScreenWidth = window.innerWidth

    const updatedState = {
      xs: newScreenWidth <= breakpointsValues.xs,
      sm: newScreenWidth > breakpointsValues.xs && newScreenWidth <= breakpointsValues.sm,
      md: newScreenWidth > breakpointsValues.sm && newScreenWidth <= breakpointsValues.md,
      lg: newScreenWidth > breakpointsValues.md && newScreenWidth <= breakpointsValues.lg,
      xl: newScreenWidth > breakpointsValues.lg && newScreenWidth <= breakpointsValues.xl,
      xxl: newScreenWidth > breakpointsValues.xl,
    }

    setScreenWidth(newScreenWidth)
    setIsMobile(newScreenWidth <= MOBILE_WIDTH_THRESHOLD)
    setBreakpoints(updatedState)
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { screenWidth, isMobile, breakpoints }
}
