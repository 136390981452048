import type { FC, JSX } from 'react'
import { useCallback, useMemo } from 'react'

import classNames from 'classnames'

import checkStatusIcon from 'features/Widget/assets/icons/check-status.svg'
import checkedIcon from 'features/Widget/assets/icons/checked.svg'
import neighborStatusIcon from 'features/Widget/assets/icons/neighbor-status.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import { WIDGET_CONSTANTS } from 'features/Widget/constants/widgetConstants'
import type { IWidgetLevel } from 'features/Widget/interfaces/IWidget'

import styles from './widgetLevel.module.scss'

interface IProps {
  userPoints: number
  pointsList: IWidgetLevel[]
}

export const WidgetLevel: FC<IProps> = ({ pointsList, userPoints }) => {
  const activeIndex: number = useMemo(
    (): number =>
      pointsList.filter(
        (pointsStatusItem: IWidgetLevel): boolean => userPoints > pointsStatusItem.points,
      ).length - 1,
    [userPoints, pointsList],
  )

  const neighborIndex: number | null = useMemo(
    (): number | null => (pointsList[activeIndex + 1] ? activeIndex + 1 : null),
    [userPoints, activeIndex, pointsList],
  )

  const getLevelIcon = useCallback(
    (index: number): string | null => {
      switch (true) {
        case activeIndex === index:
          return checkStatusIcon
        case neighborIndex === index:
          return neighborStatusIcon
        case activeIndex > index:
          return checkedIcon
        default:
          return null
      }
    },
    [activeIndex],
  )

  return (
    <div className={styles.parent}>
      <span
        style={{
          borderImage: `linear-gradient(180deg, #cb7d61 ${
            activeIndex ? activeIndex * 25 : 0
          }%, rgba(203, 125, 97, 0) 100%) 1`,
        }}
        className={styles.parentProgressBar}
      />
      {pointsList.map(
        (pointsStatusItem: IWidgetLevel, index: number): JSX.Element => (
          <div
            key={pointsStatusItem.id}
            className={classNames(
              styles.parentPointsList,
              activeIndex === index && styles.parentActiveStatus,
              neighborIndex === index && styles.parentNeighborStatus,
              activeIndex && activeIndex > index && styles.parentCheckedStatus,
            )}>
            <span className={styles.parentStatusLabel}>{pointsStatusItem.name}</span>
            <span className={styles.parentPointsLabel}>
              {pointsStatusItem.points} {WIDGET_CONSTANTS.POINTS}
            </span>
            {getLevelIcon(index) && (
              <img
                className={styles.parentActiveIcon}
                src={getLevelIcon(index)}
                alt={ALT_CONSTANTS.STATUS_ICON}
              />
            )}
          </div>
        ),
      )}
    </div>
  )
}
