import React from 'react'

import classNames from 'classnames'
import moment from 'moment'

import { ReactComponent as BoxIcon } from 'common/assets/icons/box.svg'
import { ReactComponent as DimensionIcon } from 'common/assets/icons/dimension.svg'
import { ReactComponent as LocationIcon } from 'common/assets/icons/location-pin.svg'

import { Show } from 'common/components/Show/Show'
import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import { UtilService } from 'common/services/utilService'
import { ELeases, getLeaseTypeFromCode } from 'common/types/lease.types'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import type { ILease } from 'features/Home/interfaces/IHome'

type OtherThumbnailProps = {
  lease: Omit<ILease, 'attributes'>
  hasPrice: boolean
  onLeaseClick: () => void
}

const OtherLeaseThumbnail = ({ lease, hasPrice, onLeaseClick }: OtherThumbnailProps) => {
  return (
    <div className='card-container' data-cy={`lease-${lease.id}`} onClick={onLeaseClick}>
      <button className='image'>
        <img
          src={lease.image}
          className='photo'
          alt={ALT_CONSTANTS.ACTIVE_LEASE_IMAGE}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = e.target as HTMLImageElement
            target.onerror = null
            target.src =
              'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
          }}
        />
        <div className='info'>
          <span>
            <span className='lease-id'>{lease.id}</span>
            <p
              className={classNames('content-location', {
                title: !hasPrice,
              })}>
              {lease.site_name}
              <Show when={hasPrice}>
                <LocationIcon className='location-pin' />
              </Show>
            </p>
          </span>
          <Show when={hasPrice && !lease.is_promotion}>
            <p className='lease-type'>
              {lease.lease_type_name.split('(').map((item, index, arr) => (
                <React.Fragment key={index}>
                  {index !== 0 && <br />}
                  <span>{(index === 1 && arr.length > 1 ? '(' : '') + item}</span>
                </React.Fragment>
              ))}
            </p>
          </Show>
        </div>
        <div className='box-value'>
          <TagStatus status={UtilService.capitalizeFirstLetter(lease.status) as TAG_STATUS} />
          {lease.hardware_identifier && (
            <span className='value-container'>
              <span className='value'>ID:{lease?.hardware_identifier}</span>
            </span>
          )}
          {getLeaseTypeFromCode(lease.lease_type_code) === ELeases.Mailbox && (
            <span className='value-container'>
              <BoxIcon />
              <span className='value'>{lease?.box_number}</span>
            </span>
          )}
          {getLeaseTypeFromCode(lease.lease_type_code) === ELeases.Storage && (
            <span className='value-container'>
              <DimensionIcon />
              <span className='value'>{`${lease.no_of_units} x ${lease?.unit_info}`}</span>
            </span>
          )}
        </div>
      </button>
      <div className='footer'>
        <span className='footer-date'>
          <span className='footer-date-type'>{APP_CONSTANTS.STARTS}</span>
          {moment(lease.start_date).format('ddd, MMMM D YYYY')}
        </span>
        <span className='footer-date'>
          <span className='footer-date-type'>{APP_CONSTANTS.ENDS}</span>
          {moment(lease.end_date).format('ddd, MMMM D YYYY')}
        </span>
      </div>
    </div>
  )
}

export default OtherLeaseThumbnail
