import type { FC } from 'react'

import classNames from 'classnames'

import type { IUploadedFileSrc } from 'common/interfaces/IUploadedFileSrc'

import { SupportAttachmentList } from 'features/Support/components/SupportAttachmentList/SupportAttachmentList'
import type { ITicketMessage } from 'features/Support/interfaces/ISupport'

import styles from './supportMessage.module.scss'

interface IProps {
  message: ITicketMessage
  handlePreviewFile: (file: IUploadedFileSrc) => void
}

export const SupportMessage: FC<IProps> = ({ message, handlePreviewFile }) => {
  return (
    <div className={styles.parent}>
      <div
        className={classNames(
          styles.parentHead,
          !message?.author && styles.parentHeadActive,
          message.notified && styles.parentLastMessage,
        )}>
        <span>
          <p className={styles.parentName}>{message.user.name}</p>
          {message.user.role}
        </span>
        {message.created_at}
      </div>
      <p className={styles.parentMessage}>{message.messages}</p>
      <SupportAttachmentList
        attachments={message.attachments}
        handlePreviewFile={handlePreviewFile}
      />
    </div>
  )
}
