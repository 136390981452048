import { InfoBilling } from 'features/Info/components/InfoBilling'
import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import PromotionDetailsOthers from 'features/Promotions/components/PromotionDetailsOthers/PromotionDetailsOthers'
import PromotionDetailsRooms from 'features/Promotions/components/PromotionDetailsRooms/PromotionDetailsRooms'
import PromotionDocuments from 'features/Promotions/components/PromotionDocuments/PromotionDocuments'

export enum PromotionRoutes {
  Details = 'details',
  BookingContracts = 'booking-contracts',
  PatientSchedule = 'patient-schedule',
  PatientActivity = 'patient-activity',
  Staff = 'staff',
  Notifications = 'notifications',
  Documents = 'documents',
  Checkout = 'checkout',
}

export const PromotionsTabs = [
  {
    path: PromotionRoutes.Details,
    title: 'Promotion Details',
    component: null,
  },
  {
    path: PromotionRoutes.PatientSchedule,
    title: 'Patient Schedule',
    component: null,
  },
  {
    path: PromotionRoutes.PatientActivity,
    title: INFO_CONSTANTS.PATIENT_ACTIVITY_TITLE,
    component: null,
  },
  {
    path: PromotionRoutes.Staff,
    title: 'Staff',
    component: null,
  },
  {
    path: PromotionRoutes.Notifications,
    title: 'Notifications',
    component: null,
  },
  {
    path: PromotionRoutes.Documents,
    title: 'Documents',
    component: null,
  },
]

export const PromotionsDocumentsTabs = [
  {
    path: 'contracts',
    title: 'Contracts',
    component: <PromotionDocuments />,
  },

  {
    path: 'billing',
    title: 'Billing',
    component: <InfoBilling />,
  },
]

export const PromotionsDetailsTabs = [
  {
    path: 'rooms',
    title: 'Rooms',
    component: <PromotionDetailsRooms />,
  },

  {
    path: 'others',
    title: 'Others',
    component: <PromotionDetailsOthers />,
  },
]
