import type { FC } from 'react'

import classNames from 'classnames'

import activeLinkHoverIcon from 'common/assets/icons/active-link-hover.svg'
import activeLinkIcon from 'common/assets/icons/active-link.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { SIDEBAR_CONSTANTS } from 'common/constants/sidebarConstants'
import { UtilService } from 'common/services/utilService'

// I commented next line because import file css but not using all classes in component
// eslint-disable-next-line css-modules/no-unused-class
import styles from './sidebar.module.scss'

const { getNavigationIcon } = UtilService

interface IProps {
  title: string
  hoveredTitle: string
  isActive: boolean
}

export const SidebarItem: FC<IProps> = ({ title, isActive, hoveredTitle }) => {
  return (
    <>
      {getNavigationIcon(title, title)}
      <span className={classNames({ [styles.adminMenu]: title === SIDEBAR_CONSTANTS.ADMIN })}>
        {title}
      </span>
      <Show
        when={!isActive}
        fallback={
          <img
            className={styles.activeLink}
            src={activeLinkIcon}
            alt={ALT_CONSTANTS.ACTIVE_ROUTE_ICON}
          />
        }>
        <Show when={hoveredTitle === title}>
          <img
            className={styles.activeLinkHovered}
            src={activeLinkHoverIcon}
            alt={ALT_CONSTANTS.ACTIVE_ROUTE_ICON}
          />
        </Show>
      </Show>
    </>
  )
}
