import type { FC } from 'react'
import { useState } from 'react'

import { AutoComplete as AntAutoComplete } from 'antd'
import type { AutoCompleteProps } from 'antd/lib/auto-complete'
import type { DefaultOptionType } from 'antd/lib/select'

import './autocomplete.css'

export const AutoComplete: FC<AutoCompleteProps> = ({ value, options, ...rest }) => {
  const [filteredList, setFilteredList] = useState<DefaultOptionType[]>(options)
  const onSearch = (value: string) => {
    if (value)
      setFilteredList(
        options.filter((option: DefaultOptionType): boolean =>
          String(option.value).toLowerCase().includes(value.toLowerCase()),
        ),
      )
  }

  return (
    <AntAutoComplete
      {...rest}
      onSearch={onSearch}
      options={value ? filteredList : []}
      value={value}
      disabled={rest.disabled}
    />
  )
}
