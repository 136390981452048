import type { FC, JSX } from 'react'
import { Fragment } from 'react'

import moment from 'moment'

import { Show } from 'common/components/Show/Show'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartError } from 'features/Cart/components/CartError/CartError'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import type { IRoomTimeSlot } from 'features/Lease'
import type { IOptionSlotDay } from 'features/Option'

import styles from './cartSlot.module.scss'

const { numberToDollar } = UtilService

const { formatDateTitles } = DateService

interface IProps {
  day: IOptionSlotDay
  option: ISiteRoom
  handleRemoveOption?: (date: string, option: ISiteRoom) => void
  handleRemoveSlot?: (date: string, slot: IRoomTimeSlot, option: ISiteRoom) => void
}

export const CartSlot: FC<IProps> = ({ day, option, handleRemoveSlot, handleRemoveOption }) => {
  if (!day?.selectedSlots)
    return (
      <CartCollapse
        key={day.date}
        title={moment(day.date).format(DATE_FORMAT.CREATE_LEASE)}
        handleRemoveSlot={() => handleRemoveOption(day.date, option)}>
        <div className={styles.parent}>
          {CART_CONSTANTS.QUANTITY} 1 x {UtilService.numberToDollar(day.price)}
          <span>{numberToDollar(day.price)}</span>
        </div>
      </CartCollapse>
    )

  return (
    <>
      {day.selectedSlots.map(
        (slot: IRoomTimeSlot): JSX.Element => (
          <Fragment key={slot.id}>
            <CartCollapse
              additionalActiveTitle={slot.title}
              handleRemoveSlot={() => handleRemoveSlot(day.date, slot, option)}
              title={formatDateTitles(slot.date, slot.title)}>
              <Show when={slot?.quantity} fallback={slot.title}>
                <div className={styles.parent}>
                  {CART_CONSTANTS.QUANTITY} {slot.quantity} x{' '}
                  {UtilService.numberToDollar(slot?.price)}
                  <span>{UtilService.numberToDollar(slot.quantity * slot?.price)}</span>
                </div>
              </Show>
            </CartCollapse>
            <CartError error={slot?.error_message} />
          </Fragment>
        ),
      )}
    </>
  )
}
