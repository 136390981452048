import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import { PATHS } from 'routes'

import { HTTP_RESPONSE_STATUS } from 'common/constants/httpMethodsConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import type { IUser } from 'common/interfaces/IUser'

export const baseQuery =
  (): BaseQueryFn<{
    url: string
    data?: AxiosRequestConfig['data']
    method: AxiosRequestConfig['method']
    headers?: AxiosRequestConfig['headers']
  }> =>
  async (param) => {
    try {
      const user: IUser | null = window.localStorage.getItem(LOCALE_STORAGE_KEYS.USER)
        ? JSON.parse(window.localStorage.getItem(LOCALE_STORAGE_KEYS.USER))
        : null

      const result: AxiosResponse = await axios({
        ...param,
        baseURL: process.env.REACT_APP_RESIDEN_API_BASE_URL,
        headers: {
          ...param?.headers,
          Authorization: user ? `Bearer ${user.token}` : null,
        },
      })
      return { data: result.data }
    } catch (err) {
      if (
        err.response?.status === HTTP_RESPONSE_STATUS.UNAUTHORIZED ||
        err.response?.status === HTTP_RESPONSE_STATUS.CONNECTION_TIMED_OUT
      ) {
        window.localStorage.removeItem(LOCALE_STORAGE_KEYS.USER)
        window.localStorage.removeItem(LOCALE_STORAGE_KEYS.SITES)
        window.location.assign(PATHS.LOGIN)
      }

      const errors = err.response?.data?.['error' as keyof object]
      return {
        error: {
          status: err.response?.status,
          data:
            typeof errors === 'object'
              ? Object.values(errors).flat()
              : [err.response.data?.['message' as keyof object]],
        },
      }
    }
  }
