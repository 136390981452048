import type { ReactNode, ReactElement } from 'react'
import { Children } from 'react'

type ShowProps = {
  children: ReactNode
}

type WhenProps = {
  isVisible: boolean | string | number | undefined | null
  children: ReactNode
}

type ElseProps = {
  render?: ReactNode
  children: ReactNode
}

const Show: React.FC<ShowProps> & { When: React.FC<WhenProps>; Else: React.FC<ElseProps> } = (
  props,
) => {
  let when: ReactElement | null = null
  let otherwise: ReactElement | null = null

  Children.forEach(props.children as ReactElement[], (child: ReactElement) => {
    if (!child.props.isVisible) {
      otherwise = child
    } else if (!when && child.props.isVisible) {
      when = child
    }
  })

  return when || otherwise
}

Show.When = ({ isVisible, children }: WhenProps) => (isVisible ? <>{children}</> : null)
Show.Else = ({ render, children }: ElseProps) => (render ? <>{render}</> : <>{children}</>)

export default Show
