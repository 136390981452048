import { DownloadDoc } from 'common/components/DownloadDoc/DownloadDoc'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'
import type { IColumn } from 'common/interfaces/ITable'

const fieldsValue = (columns: any) => {
  let fieldValue: any = {}

  columns.forEach((item: any) => {
    fieldValue[item.key] = ''
  })

  return fieldValue
}

const addInColumnOptions = (key: string, tableColumns: IColumn[], options: any[]) =>
  Array.isArray(options)
    ? tableColumns.map((column) => (column.key === key ? { ...column, options: options } : column))
    : tableColumns

const withDownloadPdfForColumn = <T extends object>({
  columnKey,
  columns,
  tooltipContent,
  downloadContract,
}: {
  columns: IColumn[]
  columnKey: string
  tooltipContent: string
  downloadContract: (data: T) => Promise<ApiResponse>
}): IColumn[] => {
  return columns.map((column: IColumn): IColumn => {
    if (column.key === columnKey)
      return {
        ...column,
        render: (content: string, data: T) => (
          <DownloadDoc
            content={content}
            tooltipContent={tooltipContent}
            downloadContract={() => downloadContract(data)}
          />
        ),
      }
    return column
  })
}

export const TableService = {
  fieldsValue,
  addInColumnOptions,
  withDownloadPdfForColumn,
}
