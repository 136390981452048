import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import type { IPaymentLimit } from 'common/interfaces/IPaymentLimit'
import { useFetchSitesQuery } from 'common/store/api/rootApi'

import { useCartManager } from 'features/Cart'

interface IUsePaymentLimitOut {
  paymentLimit: number
  setPurchaseConnection: Dispatch<SetStateAction<string>>
}

export const usePaymentLimit = (): IUsePaymentLimitOut => {
  const { data } = useFetchSitesQuery()
  const { site } = useParams<{ site?: string }>()
  const { payment_limit = [] } = data ?? {}

  const [purchase, setPurchase] = useState<string>(null)

  const { mailboxCart, storageCart, bookingCart } = useCartManager()

  const connection =
    purchase ||
    site ||
    bookingCart?.database_connection ||
    mailboxCart?.database_connection ||
    storageCart?.database_connection

  const paymentLimit = useMemo(() => {
    return (
      payment_limit.find(({ site }: IPaymentLimit): boolean => site === connection)?.limit ||
      Infinity
    )
  }, [connection, payment_limit])

  return { paymentLimit, setPurchaseConnection: setPurchase }
}
