import { createContext, useContext, useEffect } from 'react'
import type { FC, JSX } from 'react'

import Pusher from 'pusher-js'
import type { Channel } from 'pusher-js'

import { PUSHER_CLUSTERS, PUSHER_KEYS } from 'common/constants/siteSelectorConstants'

interface IPusherContext {
  channel: Channel
  pusher: Pusher
}

interface IPusherProviderProps {
  children: JSX.Element
  selectedSite: string
  selectedLease: string
}

const PusherContext = createContext<IPusherContext | null>(null)

const PusherProvider: FC<IPusherProviderProps> = ({ children, selectedSite, selectedLease }) => {
  const channelName = selectedLease
  const pusher = new Pusher(PUSHER_KEYS[selectedSite], {
    cluster: PUSHER_CLUSTERS[selectedSite],
  })

  const channel = pusher.subscribe(channelName)

  useEffect(() => {
    return () => {
      pusher.unsubscribe(channelName)
    }
  }, [channelName, pusher])

  const value = { channel, pusher }

  return <PusherContext.Provider value={value}>{children}</PusherContext.Provider>
}

const usePusher = (): IPusherContext => {
  const context = useContext(PusherContext)
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider')
  }

  return context
}

export { PusherProvider, usePusher }
