import type { FC } from 'react'

import { Tooltip } from 'antd'

import { Button } from 'common/components/Button/Button'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'

import styles from './downloadDoc.module.scss'

interface IProps {
  content: string
  tooltipContent: string
  downloadContract: () => Promise<ApiResponse>
}

export const DownloadDoc: FC<IProps> = ({ content, tooltipContent, downloadContract }) => {
  const { processApiResponse } = useApiResponse()

  const handleDownloadContract = async (): Promise<void> => {
    const response: ApiResponse = await downloadContract()
    processApiResponse(response, {
      successCallback: () => UtilService.downloadPdfFromResponse(response.data, content),
    })
  }

  return (
    <Tooltip title={`Download ${tooltipContent}`}>
      <Button onClick={handleDownloadContract} className={styles.parent}>
        {content}
      </Button>
    </Tooltip>
  )
}
