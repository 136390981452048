import 'antd/dist/antd.min.css'

import ReactDOM from 'react-dom/client'

import App from 'app'

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Render Application
root.render(<App />)
