import type { FC, JSX } from 'react'
import { Fragment } from 'react'

import type { RadioChangeEvent } from 'antd'
import { Radio } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'
import { Show } from 'common/components/Show/Show'

import { SettingsPaymentCard } from 'features/Settings/components/SettingsPaymentCard/SettingsPaymentCard'
import type { ISettingPaymentCard } from 'features/Settings/interfaces/ISettingPaymentCard'

import styles from './settingsPaymentCardList.module.scss'

interface IProps {
  selected: string
  isFetching: boolean
  isDisabled?: boolean
  list: ISettingPaymentCard[]
  handleAddNewCard?: () => void
  handleEditCard?: (card: ISettingPaymentCard) => void
  handleChangeMethod?: (event: RadioChangeEvent) => Promise<void> | void
}

export const SettingsPaymentCardList: FC<IProps> = ({
  list,
  isDisabled,
  isFetching,
  selected,
  handleAddNewCard,
  handleEditCard,
  handleChangeMethod,
}) => {
  return (
    <Radio.Group
      value={selected}
      className={styles.parent}
      onChange={handleChangeMethod}
      disabled={isFetching || isDisabled}>
      <Show
        when={!isFetching}
        fallback={
          <ArrayElements total={2}>
            <div className={styles.parentDisabled}>
              <Radio>
                <SettingsPaymentCard isFetching />
              </Radio>
            </div>
          </ArrayElements>
        }>
        {list.map(
          (card: ISettingPaymentCard): JSX.Element => (
            <Fragment key={card.id}>
              <Radio value={card.id}>
                <SettingsPaymentCard
                  isFetching={isFetching}
                  card={card}
                  isActive={selected === card.id}
                  handleEditCard={handleEditCard ? () => handleEditCard(card) : undefined}
                />
              </Radio>
            </Fragment>
          ),
        )}
      </Show>
      <Show when={handleAddNewCard}>
        <Radio disabled value='new_card'>
          <SettingsPaymentCard
            isFetching={isFetching}
            isDisabled
            isValidList={!!list.length}
            handleAddCard={handleAddNewCard}
          />
        </Radio>
      </Show>
    </Radio.Group>
  )
}
