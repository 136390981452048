import { useAuth, useNotification } from 'app/providers'

import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { HTTP_RESPONSE_STATUS } from 'common/constants/httpMethodsConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'

interface IProcessResponseProps {
  error?: string
  success?: string
  title?: string
  errorCallback?: () => void
  successCallback?: () => void
  description?: string | JSX.Element
}
export const useApiResponse = () => {
  const { setNotification } = useNotification()
  const auth = useAuth()
  const { setUser } = auth || {}

  const processApiResponse = (
    response: ApiResponse,
    { title, success, successCallback, error, errorCallback, description }: IProcessResponseProps,
  ): void => {
    if (Object.hasOwn(response, RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
      if (success)
        setNotification({
          title: success,
          description: description ?? response?.data.message,
          type: ALERT_CONSTANTS.SUCCESS,
        })
      if (successCallback) successCallback()
    } else {
      if (error)
        if (
          response.error?.status === HTTP_RESPONSE_STATUS.UNAUTHORIZED ||
          response.error?.status === HTTP_RESPONSE_STATUS.CONNECTION_TIMED_OUT
        ) {
          setUser(null)
        }
      setNotification({
        title: title || 'Error',
        description: response.error?.data || error,
        type: ALERT_CONSTANTS.ERROR,
      })
      if (errorCallback) errorCallback()
    }
  }

  return { processApiResponse }
}
