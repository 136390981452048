import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { UNCONFIRMED_PATHS } from 'routes/Unconfirmed/unconfirmedConfig'

import { useScreenWidth } from 'app'
import { useAuth } from 'app/providers'

import { Show } from 'common/components/Show/Show'

import { WIDGET_CONSTANTS } from 'features/Widget/constants/widgetConstants'

import styles from './widgetSettings.module.scss'

interface IProps {
  basic: boolean
  isOpen: boolean

  handleCloseWidget: () => void
}

export const WidgetSettings: FC<IProps> = ({ isOpen, basic, handleCloseWidget }) => {
  const {
    logout,
    isDoctor,
    isSuperAdminAsDoctor,
    hasCompletedDocuments,
    hasIncompleteDocuments,
    logoutAsDoctor,
  } = useAuth()
  const { isMobile } = useScreenWidth()

  if (!isOpen) return null
  return (
    <div className={styles.parent}>
      <div className={styles.parentContent}>
        <Show when={isDoctor && !hasCompletedDocuments}>
          <NavLink
            data-cy='validate-identity-link'
            onClick={handleCloseWidget}
            to={
              hasIncompleteDocuments
                ? UNCONFIRMED_PATHS.VALIDATION_PROCESSING
                : UNCONFIRMED_PATHS.VALIDATE_IDENTITY
            }
            className={styles.parentContentItem}
            data-validate-identity={true}>
            {WIDGET_CONSTANTS.VALIDATE_IDENTITY}
          </NavLink>
        </Show>
        <Show when={!(isDoctor && !hasCompletedDocuments)}>
          <NavLink
            data-cy='account-settings-link'
            to='/settings/profile'
            onClick={handleCloseWidget}
            className={styles.parentContentItem}>
            {basic ? WIDGET_CONSTANTS.ACCOUNT : WIDGET_CONSTANTS.SETTINGS}
          </NavLink>
        </Show>

        <Show when={!basic}>
          <NavLink
            data-cy='terms-link'
            onClick={handleCloseWidget}
            to='/provider/terms-and-conditions'
            className={styles.parentContentItem}>
            {WIDGET_CONSTANTS.CONDITIONS}
          </NavLink>
        </Show>

        <NavLink
          data-cy='logout-link'
          onClick={logout}
          to='/login'
          className={styles.parentContentItem}>
          {WIDGET_CONSTANTS.SIGN_OUT}
        </NavLink>

        <Show when={isSuperAdminAsDoctor && isMobile}>
          <NavLink
            data-cy='logout-as-doctor-link'
            onClick={logoutAsDoctor}
            to='/admin'
            className={styles.parentContentItem}>
            {WIDGET_CONSTANTS.SIGN_OUT_AS_DOCTOR}
          </NavLink>
        </Show>
      </div>
    </div>
  )
}
