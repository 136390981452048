import type { FC, JSX } from 'react'

import { ErrorMessage, Field, useField } from 'formik'

import { Label } from 'common/components/Label/Label'
import PhoneInput from 'common/components/PhoneInput/PhoneInput'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'

import styles from './formPhone.module.scss'

interface IProps {
  name: string
  placeholder?: string
  label?: string | JSX.Element
  onChange?: (value: any) => void
  required?: boolean
}

const FormPhone: FC<IProps> = ({ name, label, onChange, required, ...rest }) => {
  const [field, _, helpers] = useField(name)

  return (
    <div className={styles.parent}>
      <Label required={required} label={label} />

      <Field
        id={name}
        {...rest}
        {...field}
        onChange={(value: any) => {
          helpers.setValue(value)
          if (onChange) onChange(value)
        }}
        component={PhoneInput}
        appointment={field.value}
        fieldName={name}
        style={{ height: '40px' }}
        as={FORM_CONTROL_TYPE.PHONE}
        data-cy={`${name}-form-control`}
      />
      <ErrorMessage
        name={name}
        className={styles.parentErrorInform}
        render={(msg) => <p className={styles.parentErrorInform}>{msg}</p>}
      />
    </div>
  )
}

export default FormPhone
