import type { RouteProps } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { PATHS } from 'routes/paths'

import { Admin } from 'features/Admin'
import { Faq } from 'features/Faq'
import { Home, HomeLease } from 'features/Home'
import { Info } from 'features/Info'
import { Patients } from 'features/Patients'
import { Payment } from 'features/Payment'
import { PromotionsRoute } from 'features/Promotions'
import { Provider } from 'features/Provider'
import { Settings } from 'features/Settings'
import { Staff } from 'features/Staff'
import { Support, SupportTicketDetails } from 'features/Support'

enum AUTHORIZED_ROUTES {
  FAQ = 'FAQ',
  HOME = 'HOME',
  PROVIDER = 'PROVIDER',
  STAFF = 'STAFF',
  PATIENTS = 'PATIENTS',
  SUPPORT = 'SUPPORT',
  TICKET_DETAILS = 'TICKET_DETAILS',
  SETTINGS = 'SETTINGS',
  NEW_LEASE = 'NEW_BOOKING',
  BOOKING_DETAILS = 'BOOKING_DETAILS',
  PROMOTIONS = 'PROMOTIONS',
  CHECKOUT = 'CHECKOUT',
  GENERATOR = 'GENERATOR',
  ADMIN = 'ADMIN',
}

const AUTHORIZED_PATHS: Record<AUTHORIZED_ROUTES, string> = {
  [AUTHORIZED_ROUTES.ADMIN]: '/admin',
  [AUTHORIZED_ROUTES.FAQ]: '/faq',
  [AUTHORIZED_ROUTES.HOME]: '/home/:tabKey',
  [AUTHORIZED_ROUTES.PROVIDER]: '/provider/:tabKey',
  [AUTHORIZED_ROUTES.STAFF]: '/my-staff',
  [AUTHORIZED_ROUTES.PATIENTS]: '/patients',
  [AUTHORIZED_ROUTES.SUPPORT]: '/:tabKey/support',
  [AUTHORIZED_ROUTES.TICKET_DETAILS]: '/:tabKey/support/:ticketId',
  [AUTHORIZED_ROUTES.SETTINGS]: '/settings/:tabKey',
  [AUTHORIZED_ROUTES.NEW_LEASE]: '/home/ongoing/new-booking/:site',
  [AUTHORIZED_ROUTES.BOOKING_DETAILS]: '/home/:connection/bookings/:leaseId/:tabKey/*',
  [AUTHORIZED_ROUTES.PROMOTIONS]: '/home/:connection/promotions/:leaseId/*',
  [AUTHORIZED_ROUTES.CHECKOUT]: '/checkout',
  [AUTHORIZED_ROUTES.GENERATOR]: '/code/:code',
}

// Add here the restricted routes for staff
export const STAFF_RESTRICTED_ROUTES = [
  AUTHORIZED_PATHS.STAFF,
  AUTHORIZED_PATHS.NEW_BOOKING,
  AUTHORIZED_PATHS.PROVIDER,
  AUTHORIZED_PATHS.ADMIN,
]

export const AUTHORIZED_ROUTE_CONFIG: RouteProps[] = [
  {
    path: AUTHORIZED_PATHS.FAQ,
    element: <Faq />,
  },
  {
    path: AUTHORIZED_PATHS.HOME,
    element: <Home />,
  },
  {
    path: AUTHORIZED_PATHS.PROVIDER,
    element: <Provider />,
  },
  {
    path: AUTHORIZED_PATHS.SUPPORT,
    element: <Support />,
  },
  {
    path: AUTHORIZED_PATHS.TICKET_DETAILS,
    element: <SupportTicketDetails />,
  },
  {
    path: AUTHORIZED_PATHS.STAFF,
    element: <Staff />,
  },
  {
    path: AUTHORIZED_PATHS.ADMIN,
    element: <Admin />,
  },
  {
    path: AUTHORIZED_PATHS.PATIENTS,
    element: <Patients />,
  },
  {
    path: AUTHORIZED_PATHS.SETTINGS,
    element: <Settings />,
  },
  {
    path: AUTHORIZED_PATHS.NEW_BOOKING,
    element: <HomeLease />,
  },

  {
    path: AUTHORIZED_PATHS.PROMOTIONS,
    element: <PromotionsRoute />,
  },
  {
    path: AUTHORIZED_PATHS.CHECKOUT,
    element: <Payment />,
  },

  {
    path: AUTHORIZED_PATHS.BOOKING_DETAILS,
    element: <Info />,
  },

  {
    path: '*',
    element: <Navigate to={PATHS.HOME_ONGOING} replace />,
  },
]
