import React from 'react'

import { ModalAction } from 'common/components/Modal'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'

const initialState = {
  isVisible: false,
  message: '',
  secondButtonText: '',
  mainButtonText: '',
  title: '',
}

type StateType = Partial<typeof initialState>

export default class Popup extends React.Component<any, StateType> {
  sheetRef: React.RefObject<any>

  constructor(props: any) {
    super(props)
    Popup.popupRef = this
    this.sheetRef = React.createRef()

    this.state = { ...initialState }
  }
  static popupRef: any

  static showInfo(details: StateType, callback?: () => void, secondCallback?: () => void) {
    Popup.popupRef.callback = callback
    Popup.popupRef.secondCallback = secondCallback

    Popup?.popupRef?.show({
      ...details,
    })
  }

  hide(call?: string) {
    this.setState({ ...initialState }, Popup?.popupRef[call])
  }

  show(props: StateType) {
    this.setState({ isVisible: true, ...props })
  }

  render() {
    const { isVisible, message, secondButtonText, mainButtonText, title } = this.state as StateType

    return (
      <ModalAction
        isOpen={isVisible}
        confirmText={mainButtonText || BUTTON_CONSTANTS.LOGIN}
        cancelText={secondButtonText || BUTTON_CONSTANTS.CANCEL}
        title={title}
        description={message}
        onCloseIcon={() => this.hide()}
        onClose={() => this.hide('secondCallback')}
        onConfirm={() => this.hide('callback')}
        cancelButton
      />
    )
  }
}
