import type { FC, JSX } from 'react'
import { Fragment, useCallback } from 'react'

import { useCartMonitor } from 'app/providers/index'

import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartErrorList } from 'features/Cart/components/CartErrorList/CartErrorList'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartOption } from 'features/Cart/interfaces/ICartOption'
import type { IOptionSelectFixed, IOptionSelectFixedRoom } from 'features/Option'
import { OptionSelectFixedService } from 'features/Option'
import { SET_OPTION_TYPE_SELECT_FIXED } from 'features/Option/state/slices/optionSlice'

import styles from './cartOptionSelectFixedList.module.scss'

const { formatDate } = DateService
const { numberToDollar } = UtilService
const { removeRoomInOption } = OptionSelectFixedService

export const CartOptionSelectFixedList: FC<ICartOption> = ({
  handleEdit,
  handleUpdate,
  updateCartPrice,
}) => {
  const dispatch = useAppDispatch()
  const { isUpdatingCart } = useCartMonitor()

  const { newLeaseRooms: rooms } = useAppSelector((state) => state.leaseReducer)
  const {
    optionTypeSlots: optionSlots,
    optionTypeFixed: optionFixed,
    optionSelectFixed,
  } = useAppSelector((state) => state.optionReducer)

  const { bookingCart } = useCartManager()

  const optionDepList = [rooms, optionSlots, optionFixed]

  const handleRemove = useCallback(
    (code: string, option: ISiteRoom): void => {
      const updatedSelectedFixed: IOptionSelectFixed[] = removeRoomInOption(
        code,
        option.id,
        optionSelectFixed,
      )
      dispatch(SET_OPTION_TYPE_SELECT_FIXED(updatedSelectedFixed))
      handleUpdate({ optionSelectFixed: updatedSelectedFixed })
      updateCartPrice({
        rooms,
        options: [...optionSlots, ...optionFixed],
        optionSelectFixed: updatedSelectedFixed,
      })
    },
    [...optionDepList, bookingCart, isUpdatingCart, optionSelectFixed],
  )

  if (!optionSelectFixed.length) return null

  return (
    <div className='cart-card'>
      <hr />
      {optionSelectFixed?.map(
        ({ option: rOption, selectedRooms, price }: IOptionSelectFixed, index) => {
          return (
            <Fragment key={`${rOption.id}_${index}`}>
              <CartItem
                price={price}
                name={rOption.name}
                handleEdit={() => handleEdit(rOption)}
                img={rOption.images.at(0)}>
                {selectedRooms?.map((option: IOptionSelectFixedRoom, i: number): JSX.Element => {
                  const { price, name, code, start_date, end_date } = option
                  return (
                    <Fragment key={`${option.code}_${i}`}>
                      <CartCollapse
                        key={name}
                        title={name.split('-').at(0)}
                        handleRemoveSlot={() => handleRemove(code, rOption)}>
                        <div className={styles.parentContent}>
                          {CART_CONSTANTS.QUANTITY} 1 x {numberToDollar(price)}
                          <span>{numberToDollar(price)}</span>
                        </div>
                        <div className={styles.parentContent}>
                          {CART_CONSTANTS.START_DATE}
                          <span>{formatDate(start_date, DATE_FORMAT.CLASSIC_FORMAT)}</span>
                        </div>
                        <div className={styles.parentContent}>
                          {CART_CONSTANTS.END_DATE}
                          <span>{formatDate(end_date, DATE_FORMAT.CLASSIC_FORMAT)}</span>
                        </div>
                      </CartCollapse>
                      <CartErrorList errorList={rOption.error_list} />
                    </Fragment>
                  )
                })}
              </CartItem>
              <hr />
            </Fragment>
          )
        },
      )}
    </div>
  )
}
