import { useMemo } from 'react'
import type { RouteProps } from 'react-router-dom'

import {
  AUTHORIZED_ROUTE_CONFIG,
  STAFF_RESTRICTED_ROUTES,
} from 'routes/Authorized/authorizedConfig'
import { SIDEBAR_NAV_LIST } from 'routes/paths'
import { UNAUTHORIZED_ROUTE_CONFIG } from 'routes/Unauthorized/unauthorizedConfig'
import { UNCONFIRMED_ROUTE_CONFIG } from 'routes/Unconfirmed/unconfirmedConfig'

import { useAuth } from 'app/providers'

import type { ISidebarTab } from 'common/interfaces/ISidebarTab'

interface IUseAppRoutesOut {
  authorized: RouteProps[]
  unconfirmed: RouteProps[]
  unauthorized: RouteProps[]
  navigationList: ISidebarTab[]
}

export const useAppRoutes = (): IUseAppRoutesOut => {
  const { isStaff } = useAuth()

  const authorizedRotes = useMemo(
    () =>
      isStaff
        ? AUTHORIZED_ROUTE_CONFIG.filter(
            ({ path }): boolean => !STAFF_RESTRICTED_ROUTES.includes(path),
          )
        : AUTHORIZED_ROUTE_CONFIG,
    [isStaff],
  )

  const navigationList = useMemo(
    () =>
      isStaff
        ? SIDEBAR_NAV_LIST.filter(({ route }): boolean => !STAFF_RESTRICTED_ROUTES.includes(route))
        : SIDEBAR_NAV_LIST,
    [isStaff],
  )

  return {
    navigationList,
    authorized: authorizedRotes,
    unconfirmed: UNCONFIRMED_ROUTE_CONFIG,
    unauthorized: UNAUTHORIZED_ROUTE_CONFIG,
  }
}
