export const USA_COUNTRY_ID = 236

export enum SETTINGS_ADD_CARD_KEYS {
  NUMBER = 'number',
  EXPIRY_DATE = 'exp_date',
  CVC = 'cvc',
  LINE_1 = 'address_line1',
  LINE_2 = 'address_line2',
  COUNTRY = 'address_country',
  STATE = 'address_state',
  ZIP_CODE = 'address_zip',
  CITY = 'address_city',
  NAME = 'name',
}

export const INITIAL_ADD_CARD_DATA = {
  number: '',
  exp_date: '',
  cvc: '',
  address_line1: '',
  address_line2: '',
  address_country: USA_COUNTRY_ID,
  address_state: '',
  address_zip: '',
  address_city: '',
  name: '',
}

export const EXPIRY_YEAR_ERROR_LIST: string[] = [
  'incomplete_expiry',
  'invalid_expiry_year_past',
  'invalid_expiry_year',
]

export const EXPIRY_MONTH_ERROR_LIST: string[] = ['invalid_expiry_month']

export const NUMBER_ERROR_LIST: string[] = ['incomplete_number', 'invalid_number']

export const CVC_ERROR_LIST: string[] = ['incomplete_cvc', 'invalid_cvc']

export enum SETTINGS_CARD_CONSTANTS {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  JCB = 'jcb',
  DISCOVER = 'discover',
  AMEX = 'amex',
  UNION = 'unionpay',
}
