import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'
import type { TablePaginationConfig } from 'antd'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { IPatient } from 'features/Patients/interfaces/IPatient'

const { withPaginationParams } = UtilService

export const patientsApi = createApi({
  reducerPath: REDUCER_CONSTANTS.PATIENTS,
  baseQuery: baseQuery(),
  tagTypes: ['Patients'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchPatients: build.query({
      query: (params: TablePaginationConfig) => ({
        url: withPaginationParams('/patients', params),
      }),
      transformResponse: (response: IRequestData<IPatient[]>): IRequestData<IPatient[]> => ({
        ...response,
      }),
      providesTags: ['Patients'],
    }),

    createPatient: build.mutation({
      query: (patient) => ({
        url: '/patients',
        method: HTTP_METHODS.POST,
        data: patient,
      }),
      invalidatesTags: ['Patients'],
    }),
    deletePatient: build.mutation({
      query: (id) => ({
        url: `/patients/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: ['Patients'],
    }),
    updatePatient: build.mutation({
      query: ({ id, patient }) => ({
        url: `/patients/${id}`,
        method: HTTP_METHODS.PUT,
        data: patient,
      }),
      invalidatesTags: ['Patients'],
    }),
  }),
})

export const {
  useLazyFetchPatientsQuery,
  useDeletePatientMutation,
  useCreatePatientMutation,
  useUpdatePatientMutation,
}: any = patientsApi
