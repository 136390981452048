import { SIDEBAR_CONSTANTS } from 'common/constants/sidebarConstants'
import type { ISidebarTab } from 'common/interfaces/ISidebarTab'

export enum PATHS {
  FAQ = '/faq',
  STAFF = '/my-staff',
  ADMIN = '/admin',
  PATIENTS = '/patients',
  HOME_ONGOING = '/home/ongoing',
  GENERATOR = '/code/:code',
  PAYMENT = '/settings/payment',
  LOGIN = '/login',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset-password',
  CHECKOUT = '/checkout',
  VALIDATE_IDENTITY = '/validate-doctor-credentials',
  VALIDATION_PROCESSING = '/status-processing-documents',
  START_VALIDATION_PROCESS = '/start-processing-documents',
  VALIDATION_RESULT = '/processing-result',
}

export const SIDEBAR_NAV_LIST: ISidebarTab[] = [
  {
    baseRoute: 'home',
    route: PATHS.HOME_ONGOING,
    title: SIDEBAR_CONSTANTS.HOME,
  },
  {
    baseRoute: 'my-staff',
    route: PATHS.STAFF,
    title: SIDEBAR_CONSTANTS.STAFF,
    isBlockedForGuest: true,
    isBlockedForUnconfirmedDoctor: true,
  },
  {
    baseRoute: 'patients',
    route: PATHS.PATIENTS,
    title: SIDEBAR_CONSTANTS.PATIENTS,
    isBlockedForGuest: true,
    isBlockedForUnconfirmedDoctor: true,
  },

  {
    baseRoute: 'support',
    route: '/_/support',
    title: SIDEBAR_CONSTANTS.SUPPORT,
    isBlockedForGuest: true,
  },
  {
    baseRoute: 'faq',
    route: PATHS.FAQ,
    title: SIDEBAR_CONSTANTS.FAQ,
  },
  {
    baseRoute: 'admin',
    route: PATHS.ADMIN,
    title: SIDEBAR_CONSTANTS.ADMIN,
  },
]
