import './other-leases-thumbnails.scss'

import { useCallback } from 'react'

import { useAuth } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import { ThumbnailSkeletonList } from 'common/components/Thumbnail/ThumbnailSkeletonList'
import { LEASE_STATUS_CODE } from 'common/constants/leaseConstants'
import useNavigateParams from 'common/hooks/useNavigateParams'
import { ELeaseParams, getLeaseTypeFromCode } from 'common/types/lease.types'

import type { ILease } from 'features/Home/interfaces/IHome'
import { PromotionRoutes } from 'features/Promotions/constants/promotionsRoutes'

import OtherLeaseThumbnail from './OtherLeaseThumbnail'

type OtherThumbnailsProps = {
  leases: Array<Omit<ILease, 'attributes'>>
  isLoading: boolean
}
const OtherLeasesThumbnails = ({ leases = [], isLoading }: OtherThumbnailsProps) => {
  const navigate = useNavigateParams()
  const { isDoctor } = useAuth()

  const onLeaseClick = useCallback((lease: Omit<ILease, 'attributes'>): void => {
    const { database_connection, is_promotion, id, lease_type_code, status_code } = lease
    if (is_promotion) {
      const mainPath =
        status_code === LEASE_STATUS_CODE.WAITING_TO_SIGN
          ? PromotionRoutes.BookingContracts
          : PromotionRoutes.Details
      return navigate(`/home/${database_connection}/promotions/${id}/${mainPath}`)
    }
    navigate(`/home/${database_connection}/bookings/${id}/details`, {
      [ELeaseParams.Type]: getLeaseTypeFromCode(lease_type_code),
    })
  }, [])

  return (
    <div className='other-leases-thumbnails-container'>
      <Show
        when={!isLoading}
        fallback={<ThumbnailSkeletonList rows={0} maxWidth={417} imgHeight={132} />}>
        {leases.map((lease) => (
          <OtherLeaseThumbnail
            lease={lease}
            key={lease.id}
            hasPrice={isDoctor}
            onLeaseClick={() => onLeaseClick(lease)}
          />
        ))}
      </Show>
    </div>
  )
}

export default OtherLeasesThumbnails
