import { type FC, type JSX } from 'react'

import type { UploadFile } from 'antd'
import classNames from 'classnames'
import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import statusAddMessage from 'common/assets/icons/summary-small.svg'
import uploadIcon from 'common/assets/icons/upload.svg'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'

import { SUPPORT_CONSTANTS } from 'features/Support/constants/supportConstants'
import { NEW_MESSAGE_SCHEMA } from 'features/Support/schemas/supportNewMessageSchema'

import styles from './supportNewMessage.module.scss'

interface IProps {
  isAddMessage: boolean
  isClosed: boolean
  isCreatingMessage: boolean
  uploadedFiles: UploadFile[]
  setUploadedFiles: (files: UploadFile[]) => void
  toggleIsOpenAddMessage: () => void
  handleSendMessage: (values: FormikValues, resetForm: () => void) => Promise<void>
}

export const SupportNewMessage: FC<IProps> = ({
  isClosed,
  isAddMessage,
  isCreatingMessage,
  toggleIsOpenAddMessage,
  handleSendMessage,
}) => {
  if (isClosed) return <div className={styles.parentClosed}>{SUPPORT_CONSTANTS.TICKET_CLOSED}</div>

  return (
    <>
      <div
        className={classNames(styles.parent, {
          [styles.parentActive]: isAddMessage,
        })}
        onClick={toggleIsOpenAddMessage}>
        {SUPPORT_CONSTANTS.ADD_MESSAGE}
        {!isAddMessage ? (
          <img src={statusAddMessage} alt={ALT_CONSTANTS.STATUS_ICON} />
        ) : (
          <div className={styles.parentMessageManager}>
            <Button
              disabled={isCreatingMessage}
              modifier={BUTTON_MODIFIER.DEFAULT}
              proportion={BUTTON_PROPORTION.SMALL}>
              {BUTTON_CONSTANTS.CANCEL}
            </Button>
            <Button
              htmlType='submit'
              form='message-form'
              disabled={isCreatingMessage}
              modifier={BUTTON_MODIFIER.TERTIARY}
              proportion={BUTTON_PROPORTION.SMALL}
              onClick={(e) => {
                e.stopPropagation()
              }}>
              {BUTTON_CONSTANTS.SUBMIT}
            </Button>
          </div>
        )}
      </div>

      <Formik
        initialValues={{ message: '', attachments: [] }}
        validationSchema={NEW_MESSAGE_SCHEMA}
        onSubmit={(values: FormikValues, { resetForm }) => {
          handleSendMessage(values, resetForm)
        }}>
        {(): JSX.Element => (
          <Form className={styles.parentMessageContentItem} id='message-form'>
            <Show when={isAddMessage}>
              <FormControl
                type={INPUT_TYPES.TEXT}
                control={FORM_CONTROL_TYPE.TEXTAREA}
                name='message'
              />
              <FormControl
                type={INPUT_TYPES.TEXT}
                control={FORM_CONTROL_TYPE.UPLOAD}
                name='attachments'
                maxCount={10}
                label='Attach images'
                additional={uploadIcon}
              />
            </Show>
          </Form>
        )}
      </Formik>
    </>
  )
}
