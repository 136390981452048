import React from 'react'

import Overlay from 'common/components/Overlay/Overlay'

type StateType = {
  isVisible?: boolean
  title?: string
  children: JSX.Element
  callback?: () => {}
  params?: Record<string, any>
}

const initialState: StateType = {
  isVisible: false,
  title: '',
  children: null,
  callback: () => undefined,
  params: {},
}

export default class GenericModal extends React.Component<any, StateType> {
  overlayRef: React.RefObject<any>
  callback?: () => void

  constructor(props: any) {
    super(props)
    GenericModal.modalRef = this
    this.overlayRef = React.createRef()
    this.state = { ...initialState }
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
  }
  static modalRef: GenericModal | null = null

  static showModal(modalProps: StateType, callback?: () => void) {
    if (callback) GenericModal.modalRef.callback = callback
    GenericModal?.modalRef?.show({
      ...modalProps,
    })
  }

  static hideModal(callback?: () => void) {
    if (callback) GenericModal.modalRef.callback = callback
    GenericModal?.modalRef?.hide()
  }

  static passParams(params: Record<string, any>) {
    GenericModal?.modalRef?.setState({ params })
  }

  hide() {
    this.setState({ ...initialState }, GenericModal?.modalRef?.callback)
  }

  show(props: StateType) {
    this.setState({ isVisible: true, ...props })
  }

  render() {
    const { isVisible, title, children, params } = this.state as StateType

    const childWithProps = React.isValidElement(children)
      ? React.cloneElement(children, { ...params })
      : children

    return (
      <Overlay title={title} isOpen={isVisible} onClose={this.hide}>
        {childWithProps}
      </Overlay>
    )
  }
}
