import type { FC } from 'react'

import classNames from 'classnames'

import { Show } from 'common/components/Show/Show'

import styles from './label.module.scss'

interface IProps {
  id?: string
  label?: string | JSX.Element
  required?: boolean
  className?: string
}

export const Label: FC<IProps> = ({ id, label, required, className }) => {
  if (!label) return null
  return (
    <Show when={label}>
      <label htmlFor={id} className={classNames(styles.parent, className)}>
        <Show when={required} fallback={label}>
          {label}
          <span className={styles.parentRequired}> *</span>
        </Show>
      </label>
    </Show>
  )
}
