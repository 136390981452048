import './promotion-calendar-card.scss'

import { Col, Row } from 'antd'
import classNames from 'classnames'

import type { PromotionRoom } from 'features/Promotions/types/promotion.types'

type Props = {
  room: PromotionRoom
  className?: string
}

const PromotionCalendarCard = ({ room, className }: Props) => {
  return (
    <div className={classNames('promotion-calendar-card', className)}>
      <p className='title'>{room.name}</p>

      <Row gutter={[12, 12]} justify='center'>
        {room.weekday_slots >= 0 && (
          <Col xs={12} md={24} className='center'>
            <p className='week-days'>{room.weekday_slots} week-day slots</p>
          </Col>
        )}
        {room.weekend_slots >= 0 && (
          <Col xs={12} md={24} className='center'>
            <p className='weekend-days'>{room.weekend_slots} week-end slots</p>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default PromotionCalendarCard
