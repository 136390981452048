import { WIDGET_CONSTANTS } from 'features/Widget/constants/widgetConstants'

const splitPoints = (points: number, suffix: string): string => {
  return `${points.toString().split('.').at(0)}${suffix}`
}

const getPoints = (points: number): string => {
  if (points < 1000) return `${Math.round(points)}${WIDGET_CONSTANTS.PTS}`

  let resultPoints: string = points.toString()

  if (points >= 1000000) resultPoints = splitPoints(points / 1000000, 'M')
  else if (points >= 1000) resultPoints = splitPoints(points / 1000, 'k')

  return `${resultPoints}${WIDGET_CONSTANTS.PTS}`
}

export const WidgetService = { getPoints }
