import type { RouteProps } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import {
  AuthLogin,
  AuthRegister,
  AuthResetPassword,
  AuthSetNewPassword,
  AuthValidateEmail,
} from 'features/Auth'
import { AuthTermsAndConditions } from 'features/Auth/components/AuthTermsAndConditions/AuthTermsAndConditions'

enum UNAUTHORIZED_ROUTES {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VALIDATE_EMAIL = 'VALIDATE_EMAIL',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}

const UNAUTHORIZED_PATHS: Record<UNAUTHORIZED_ROUTES, string> = {
  [UNAUTHORIZED_ROUTES.LOGIN]: '/login',
  [UNAUTHORIZED_ROUTES.REGISTER]: '/register',
  [UNAUTHORIZED_ROUTES.RESET_PASSWORD]: '/reset-password',
  [UNAUTHORIZED_ROUTES.VALIDATE_EMAIL]: '/validate-email/:email',
  // Need updating
  [UNAUTHORIZED_ROUTES.SET_NEW_PASSWORD]: '/auth/set-new-password',
  [UNAUTHORIZED_ROUTES.TERMS_AND_CONDITIONS]: '/terms-and-conditions',
}

export const UNAUTHORIZED_ROUTE_CONFIG: RouteProps[] = [
  {
    path: UNAUTHORIZED_PATHS.LOGIN,
    element: <AuthLogin />,
  },
  {
    path: UNAUTHORIZED_PATHS.REGISTER,
    element: <AuthRegister />,
  },
  {
    path: UNAUTHORIZED_PATHS.VALIDATE_EMAIL,
    element: <AuthValidateEmail />,
  },
  {
    path: UNAUTHORIZED_PATHS.RESET_PASSWORD,
    element: <AuthResetPassword />,
  },
  {
    path: UNAUTHORIZED_PATHS.SET_NEW_PASSWORD,
    element: <AuthSetNewPassword />,
  },
  {
    path: UNAUTHORIZED_PATHS.TERMS_AND_CONDITIONS,
    element: <AuthTermsAndConditions />,
  },

  {
    path: '*',
    element: <Navigate to={UNAUTHORIZED_PATHS.LOGIN} replace />,
  },
]
