import Header from 'layout/Header/Header'

import { ProviderConditions } from 'features/Provider/components/ProviderConditions'

import styles from './authTermsAndConditions.module.scss'

export const AuthTermsAndConditions = () => {
  return (
    <div className={styles.parent}>
      <Header />
      <div className={styles.parentTermsAndConditions}>
        <ProviderConditions />
      </div>
    </div>
  )
}
