import type { FC, JSX } from 'react'
import { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { SidebarItem } from 'layout/Sidebar/SidebarItem'
import { PATHS, useAppRoutes } from 'routes'

import { useScreenWidth } from 'app'
import { useAuth } from 'app/providers'

import type { ISidebarTab } from 'common/interfaces/ISidebarTab'
import { UtilService } from 'common/services/utilService'

// I commented next line because import file css but not using all classes in component
// eslint-disable-next-line css-modules/no-unused-class
import styles from './sidebar.module.scss'
interface IProps {
  isSidebarVisible?: boolean
  toggleIsSidebarVisible?: () => void
}
const Sidebar: FC<IProps> = ({ isSidebarVisible, toggleIsSidebarVisible }) => {
  const { user, isDoctor, hasCompletedDocuments } = useAuth()
  const { pathname } = useLocation()

  const { navigationList } = useAppRoutes()
  const { isSuperAdminAsDoctor } = useAuth()

  const [hoveredNavigation, setHoveredNavigation] = useState<string | null>(null)

  const handleChangeHoveredIcon = (newHoveredIcon: string): void => {
    if (hoveredNavigation !== newHoveredIcon) setHoveredNavigation(newHoveredIcon)
  }
  const { isMobile } = useScreenWidth()
  const navigate = useNavigate()
  return (
    <div className={styles.sidebar} data-guest={!user}>
      <div className={styles.sidebarNavigation}>
        {navigationList
          .filter(
            (route) =>
              !(route.route === PATHS.ADMIN && (isSuperAdminAsDoctor || !user?.is_super_doctor)),
          )
          .map(
            (
              {
                route,
                title,
                baseRoute,
                isBlockedForGuest = false,
                isBlockedForUnconfirmedDoctor = false,
              }: ISidebarTab,
              index: number,
            ): JSX.Element => {
              const isActiveRoute: boolean =
                route.includes(baseRoute) && pathname.includes(baseRoute)

              const isUnconfirmedDoctor =
                isDoctor && !hasCompletedDocuments && isBlockedForUnconfirmedDoctor
              const isGuest = isBlockedForGuest && !user
              return (
                <NavLink
                  key={index}
                  data-cy={`sidebar-btn-${UtilService.replaceSpaceToUnderscore(title)}`}
                  onClick={(e: any) => {
                    if (isGuest || isUnconfirmedDoctor) {
                      e.preventDefault()
                    }
                    if (isSidebarVisible && isMobile) {
                      e.preventDefault()
                      toggleIsSidebarVisible?.()
                    }
                    navigate(route)
                  }}
                  data-disabled={isGuest || isUnconfirmedDoctor}
                  onMouseLeave={() => setHoveredNavigation(null)}
                  onMouseOver={() => handleChangeHoveredIcon(title)}
                  className={classNames(styles.navigationItem, styles.activeNavigationItem, {
                    [styles.activeAdminItem]: route === PATHS.ADMIN,
                    [styles.disabledRoute]: isGuest || isUnconfirmedDoctor,
                  })}
                  to={route}>
                  <SidebarItem
                    title={title}
                    hoveredTitle={hoveredNavigation}
                    isActive={isActiveRoute}
                  />
                </NavLink>
              )
            },
          )}
      </div>
    </div>
  )
}

export default Sidebar
