import type { FC } from 'react'

import { Show } from 'common/components/Show/Show'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { UtilService } from 'common/services/utilService'

import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import type { IRoomTimeSlot } from 'features/Lease'
import type { IOptionShopCartOption, IOptionShopRoom } from 'features/Option'

import styles from './cartShopItem.module.scss'

interface IProps {
  shopOption: IOptionShopCartOption
  handleEdit: (option: ISiteRoom) => void
  handleRemoveSlot: (roomId: number, slotId: number) => void
}

export const CartShopItem: FC<IProps> = ({
  handleEdit,
  handleRemoveSlot,
  shopOption: { option, rooms, amount },
}) => {
  return (
    <CartItem
      price={amount}
      key={option.id}
      name={option.name}
      handleEdit={() => handleEdit(option)}
      img={option.images.at(0)}>
      {rooms.map(({ room, selectedSlots, error_message }: IOptionShopRoom) =>
        selectedSlots.map((slot: IRoomTimeSlot) => (
          <CartCollapse
            key={slot.id}
            title={room.name}
            handleRemoveSlot={() => handleRemoveSlot(room.code as number, slot.id)}
            error={error_message}
            additionalActiveTitle={slot.title}>
            <Show when={slot?.quantity}>
              <div className={styles.parent}>
                {CART_CONSTANTS.QUANTITY} {slot.quantity} x{' '}
                {UtilService.numberToDollar(slot?.price)}
                <span>{UtilService.numberToDollar(slot.quantity * slot?.price)}</span>
              </div>
            </Show>
          </CartCollapse>
        )),
      )}
    </CartItem>
  )
}
