import type { FC } from 'react'
import type { Country } from 'react-phone-number-input'
import { useToggle } from 'react-use'

import { Skeleton } from 'antd'

import { Select } from 'common/components/Select/Select'
import { ALT_CONSTANTS } from 'common/constants/altConstants'

import styles from './phoneInputSelect.module.scss'

import 'antd/lib/skeleton/style/css'

interface IProps {
  data: object
  country: Country
  isLoading?: boolean
}

export const PhoneInputSelect: FC<IProps> = ({ data, isLoading, country }) => {
  const [isOpen, toggleIsOpen] = useToggle(false)

  if (isLoading)
    return <Skeleton className={styles.parentSkeleton} paragraph={{ rows: 0 }} active />

  return (
    <div className={styles.parent}>
      <button type='button' onClick={toggleIsOpen}>
        <img
          className={styles.parentIcon}
          alt={ALT_CONSTANTS.COUNTRY_ICON}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
        />
      </button>

      <Select
        {...data}
        open={isOpen}
        suffixIcon={null}
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={toggleIsOpen}
        popupClassName='table-select-content'
      />
    </div>
  )
}
