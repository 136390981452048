const fullName = (): RegExp =>
  /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g
const name = (): RegExp => /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/

const postalCode = (): RegExp => /(^\d{5}$)|(^\d{5}-\d{4}$)/

const onlyLatin = (): RegExp => /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi
const onlyLatinWithSpaces = () => /^[a-zA-Z\s]*$/
const includeSpace = (): RegExp => /\s/

const emailTable = (): RegExp => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const email = (): RegExp => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/

const address = (): RegExp => /^[a-zA-Z]/

const onlyNumbers = (): RegExp => /^[0-9]/

const phoneNumber = (): RegExp => /^\+[1-9]\d{1,14}$/

const includeLowerCase = (): RegExp => /^(?=.*[a-z])/

const includeUpperCase = (): RegExp => /^(?=.*[A-Z])/

const includePhoneNumber = (): RegExp => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

const number = (): RegExp => /^(?=.*[0-9])/

const includeSpecialChar = (): RegExp => /^(?=.*[!@#\$%\^&\*])/

const passwordMinLength = (): RegExp => /^.{8,}$/
const minLength = (total: number): RegExp => new RegExp(`^.{${total},}$`)
const minLengthWithoutSpaces = (total: number): RegExp =>
  new RegExp(`^(?!\\s+$)(?!\\s)[\\w ]{${total},}$`)
const accessCode = (): RegExp => /^[0-9]{4}$/

const zipCode = (): RegExp => /^\d{5}(?:[-\s]\d{4})?$/

const timeSplitter = (): RegExp => /[:\s]/
const timeFormat12Hour = (): RegExp => /^(\d{1,2}):(\d{2})(AM|PM)$/
const timeFormat24Hour = (): RegExp => /^(\d{1,2}):(\d{2})$/
const removeSpaces = (): RegExp => /\s+/g

export const RegexService = {
  accessCode,
  fullName,
  postalCode,
  email,
  zipCode,
  address,
  onlyNumbers,
  phoneNumber,
  includeSpace,
  onlyLatin,
  minLength,
  includeUpperCase,
  includePhoneNumber,
  includeSpecialChar,
  includeLowerCase,
  passwordMinLength,
  emailTable,
  name,
  number,
  minLengthWithoutSpaces,
  onlyLatinWithSpaces,
  timeSplitter,
  timeFormat12Hour,
  timeFormat24Hour,
  removeSpaces,
}
