import { useAuth } from 'app/providers'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'

import type { IOptionShopCartItem } from 'features/Option/interfaces/IOptionShopCartItem'
import {
  useCreateShopCartMutation,
  useDeleteShopCartMutation,
  useLazyFetchShopCartQuery,
  useUpdateShopCartMutation,
} from 'features/Option/state/api/optionApi'
import { UPDATE_OPTION_SHOP_ITEMS } from 'features/Option/state/slices/optionSlice'

const { deepEqual } = UtilService

export const useShopCartManager = () => {
  const { user } = useAuth()

  const { processApiResponse } = useApiResponse()

  const dispatch = useAppDispatch()
  const [fetchCart]: ITriggerRequest = useLazyFetchShopCartQuery()
  const [createCart]: ITriggerRequest = useCreateShopCartMutation({
    fixedCacheKey: 'creating-shop-cart',
  })
  const [deleteCart]: ITriggerRequest = useDeleteShopCartMutation({
    fixedCacheKey: 'deleting-shop-cart',
  })
  const [updateCart]: ITriggerRequest = useUpdateShopCartMutation({
    fixedCacheKey: 'updating-shop-cart',
  })

  const { optionShopItems } = useAppSelector((state) => state.optionReducer)

  const fetchShopCartData = async (): Promise<void> => {
    if (!user) return
    const response: ApiResponse = await fetchCart(null, false)
    processApiResponse(response, {
      successCallback: () => {
        dispatch(UPDATE_OPTION_SHOP_ITEMS(response.data))
      },
      error: 'Fetch shop cart error',
    })
  }

  const updateShopCartContent = async (data: IOptionShopCartItem[]): Promise<void> => {
    const isEmptyPropCart: boolean = !data.length
    const isEmptyShopCart: boolean = !optionShopItems.length
    switch (true) {
      case !isEmptyPropCart && !isEmptyShopCart:
        const responseUpdate: ApiResponse = await updateCart(data)
        // If an error occurs during the update cart, then it returns to the previous value of the lease
        processApiResponse(responseUpdate, {
          error: 'Update cart error',
          successCallback: () => {
            if (!deepEqual(responseUpdate.data, optionShopItems))
              dispatch(UPDATE_OPTION_SHOP_ITEMS(responseUpdate.data))
          },
        })
        return
      case isEmptyPropCart && !isEmptyShopCart:
        const responseDelete: ApiResponse = await deleteCart()
        processApiResponse(responseDelete, { error: 'Delete cart error' })
        return
      case isEmptyShopCart && !isEmptyPropCart:
        const responseCreate: ApiResponse = await createCart(data)
        processApiResponse(responseCreate, {
          error: 'Create cart error',
          successCallback: () => {
            dispatch(UPDATE_OPTION_SHOP_ITEMS(responseCreate.data))
          },
        })
        return
      default:
        return
    }
  }
  return { fetchShopCartData, updateShopCartContent }
}
