import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'

import type {
  ILeaseInfoRoom,
  IRoomOptionProduct,
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Lease/interfaces/ILeaseInfoRoom'
import { LeaseService } from 'features/Lease/services/leaseService'
import type { ILeaseSlice } from 'features/Lease/state/interfaces/ILeaseSlice'

const initialState: ILeaseSlice = {
  newLeaseInfo: {
    price: 0,
    slots: [],
    room: null,
    days: null,
    selectedDays: [],
    selectedDay: null,
  },
  newLeaseRooms: [],
  isCreateNewLease: false,
  reservedService: [],
}

export const leaseSlice = createSlice({
  name: REDUCER_CONSTANTS.LEASE,
  initialState: initialState,
  reducers: {
    UPDATE_NEW_LEASE_ROOM_INFO(state, action: PayloadAction<ILeaseInfoRoom>) {
      state.newLeaseInfo = { ...state.newLeaseInfo, ...action.payload }
    },

    UPDATE_NEW_LEASE_ROOM_DATE_INFO(state, action: PayloadAction<IRoomSelectedDay>) {
      const { selectedDays, selectedDay } = state.newLeaseInfo
      const reservedDay: IRoomSelectedDay | undefined = selectedDays.find(
        (selectedDay: IRoomSelectedDay): boolean => selectedDay.date === action.payload.date,
      )
      if (reservedDay) state.newLeaseInfo.selectedDay = reservedDay
      else state.newLeaseInfo.selectedDay = { ...selectedDay, ...action.payload }
    },

    FILTER_NEW_LEASE_SELECTED_SLOTS(state, action: PayloadAction<IRoomTimeSlot>) {
      const { selectedDay, selectedDays } = state.newLeaseInfo
      const filteredSlots: IRoomSelectedTimeSlot[] = selectedDay.selectedSlots.filter(
        (selectedSlot: IRoomSelectedTimeSlot): boolean => selectedSlot.id !== action.payload.id,
      )

      if (!filteredSlots.length)
        state.newLeaseInfo.selectedDays = selectedDays.filter(
          (day: IRoomSelectedDay): boolean => day.date !== selectedDay.date,
        )
      else
        state.newLeaseInfo.selectedDays = selectedDays.map((day: IRoomSelectedDay) =>
          day.date === selectedDay.date ? selectedDay : day,
        )

      state.newLeaseInfo.selectedDay.selectedSlot = null
      state.newLeaseInfo.selectedDay.selectedSlots = filteredSlots
    },

    UPDATE_NEW_LEASE_SELECTED_DAY(state, action: PayloadAction<IRoomSelectedDay>) {
      state.newLeaseInfo.selectedDay = action.payload
    },

    UPDATE_NEW_LEASE_SELECTED_SLOTS(state, action: PayloadAction<IRoomSelectedTimeSlot[]>) {
      state.newLeaseInfo.selectedDay.selectedSlots = action.payload
    },

    UPDATE_NEW_LEASE_ROOM_DAYS(state) {
      const { selectedDay, selectedDays } = state.newLeaseInfo
      const selectedDayIndex: number = selectedDays.findIndex(
        (day: IRoomSelectedDay): boolean => day.date === selectedDay.date,
      )
      if (selectedDayIndex === -1) state.newLeaseInfo.selectedDays.push(selectedDay)
      else state.newLeaseInfo.selectedDays.splice(selectedDayIndex, 1, selectedDay)
    },

    UPDATE_NEW_LEASE_SELECTED_SLOT(state, action: PayloadAction<IRoomTimeSlot | null>) {
      const { selectedSlots } = state.newLeaseInfo.selectedDay
      if (action.payload) {
        const activeTimeSlot: IRoomSelectedTimeSlot | undefined = selectedSlots?.find(
          (slot: IRoomSelectedTimeSlot): boolean => slot.id === action.payload.id,
        )
        if (activeTimeSlot)
          state.newLeaseInfo.selectedDay.selectedSlot = { ...activeTimeSlot, ...action.payload }
        else
          state.newLeaseInfo.selectedDay.selectedSlot = {
            ...action.payload,
            selectedOptions: [],
          }
      } else state.newLeaseInfo.selectedDay.selectedSlot = null
    },

    UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS(state, action: PayloadAction<IRoomOptionProduct[]>) {
      state.newLeaseInfo.selectedDay.selectedSlot.selectedOptions = action.payload
    },

    UPDATE_SELECTED_SLOT_OPTION_STOCK(state, action: PayloadAction<IRoomOptionProduct>) {
      const { selectedOptions } = state.newLeaseInfo.selectedDay.selectedSlot
      state.newLeaseInfo.selectedDay.selectedSlot.selectedOptions = selectedOptions.map(
        (selectedOption: IRoomOptionProduct): IRoomOptionProduct =>
          selectedOption.id === action.payload.id ? action.payload : selectedOption,
      )
    },

    UPDATE_NEW_LEASE_PRICE(state) {
      const { room, selectedDays } = state.newLeaseInfo
      let updatedLeasePrice: number = 0

      if (room) {
        updatedLeasePrice = LeaseService.getLeaseRoomsPrice(selectedDays)
        state.newLeaseInfo.price = updatedLeasePrice
      }
    },

    TOGGLE_IS_CREATE_NEW_LEASE(state) {
      state.isCreateNewLease = !state.isCreateNewLease
    },

    RESET_NEW_LEASE_ROOM_INFO(state) {
      state.newLeaseInfo = initialState.newLeaseInfo
    },

    SET_NEW_LEASE_ROOMS(state, action: PayloadAction<ILeaseInfoRoom[]>) {
      state.newLeaseRooms = action.payload
    },
  },
})

export const {
  UPDATE_NEW_LEASE_PRICE,
  UPDATE_NEW_LEASE_ROOM_INFO,
  RESET_NEW_LEASE_ROOM_INFO,
  UPDATE_NEW_LEASE_ROOM_DAYS,
  UPDATE_NEW_LEASE_SELECTED_DAY,
  TOGGLE_IS_CREATE_NEW_LEASE,
  UPDATE_NEW_LEASE_SELECTED_SLOT,
  UPDATE_SELECTED_SLOT_OPTION_STOCK,
  UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS,
  FILTER_NEW_LEASE_SELECTED_SLOTS,
  UPDATE_NEW_LEASE_ROOM_DATE_INFO,
  SET_NEW_LEASE_ROOMS,
} = leaseSlice.actions
