import type { ComponentType, FC } from 'react'

import { CartProvider } from 'app/providers/cart/CartProvider'

export const withCartMonitor = <T extends object>(Component: ComponentType<T>): FC<T> => {
  const WithCartMonitor: FC<T> = (props) => {
    return (
      <CartProvider>
        <Component {...props} />
      </CartProvider>
    )
  }

  return WithCartMonitor
}
