import type { FC, JSX } from 'react'

import type { IUploadedFileSrc } from 'common/interfaces/IUploadedFileSrc'

import { SupportAttachment } from 'features/Support/components/SupportAttachment/SupportAttachment'

import styles from './supportAttachmentList.module.scss'

interface IProps {
  attachments: IUploadedFileSrc[]
  handlePreviewFile: (file: IUploadedFileSrc) => void
}

export const SupportAttachmentList: FC<IProps> = ({ attachments, handlePreviewFile }) => {
  if (!attachments.length) return null

  return (
    <div className={styles.parent}>
      {attachments.map(
        (attachItem: IUploadedFileSrc): JSX.Element => (
          <SupportAttachment
            key={attachItem.id}
            name={attachItem.name}
            handlePreviewFile={() => handlePreviewFile(attachItem)}
          />
        ),
      )}
    </div>
  )
}
