export const BEVERLY_HILLS_STAGING = 'beverly-hills-staging'
export const LOS_ANGELES_STAGING = 'los-angeles-staging'
export const BEVERLY_HILLS_PRODUCTION = 'beverly-hills'
export const BEVERLY_HILLS_SECOND_PRODUCTION = 'beverly-hills-second'
export const BRENTWOOD = 'brentwood'

export const SQUARE_APP_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID || ''

export const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY || ''

export const PUSHER_KEYS: Record<string, string> = {
  [BEVERLY_HILLS_STAGING]: process.env.REACT_APP_PUSHER_KEY_SITE_1 || '',
  [LOS_ANGELES_STAGING]: process.env.REACT_APP_PUSHER_KEY_SITE_2 || '',
  [BEVERLY_HILLS_PRODUCTION]: process.env.REACT_APP_PUSHER_KEY_SITE_1 || '',
  [BEVERLY_HILLS_SECOND_PRODUCTION]: process.env.REACT_APP_PUSHER_KEY_SITE_2 || '',
  [BRENTWOOD]: process.env.REACT_APP_PUSHER_KEY_SITE_3 || '',
}

export const PUSHER_CLUSTERS: Record<string, string> = {
  [BEVERLY_HILLS_STAGING]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_1 || '',
  [LOS_ANGELES_STAGING]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_2 || '',
  [BEVERLY_HILLS_PRODUCTION]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_1 || '',
  [BEVERLY_HILLS_SECOND_PRODUCTION]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_2 || '',
  [BRENTWOOD]: process.env.REACT_APP_PUSHER_CLUSTER_SITE_3 || '',
}
