export enum HTTP_METHODS {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum HTTP_RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  CONNECTION_TIMED_OUT = 522,
}
