import { UtilService } from 'common/services/utilService'

import type { IRoomTimeSlot } from 'features/Lease'
import type { IOptionSlot } from 'features/Option/interfaces/IOption'
import type { IOptionSlotDay } from 'features/Option/interfaces/IOption'
import type {
  IGetUpdatedDayProps,
  IGetUpdatedOptionProps,
  IRemoveSlotInOptionsProps,
} from 'features/Option/interfaces/IOptionService'

const { structuredClone } = UtilService

const getOptionSlotById = (optionId: number, options: IOptionSlot[]): IOptionSlot | undefined =>
  options.find((selectedOption: IOptionSlot): boolean => selectedOption.option.id === optionId)

const getUpdatedOptionSlots = (slot: IOptionSlot, slots: IOptionSlot[]): IOptionSlot[] => {
  let updatedSlots = []
  const slotIndex = slots.findIndex((rSlot: IOptionSlot) => rSlot.option.id === slot.option.id)
  if (slotIndex === -1) updatedSlots = [...slots, slot]
  else
    updatedSlots = slots.map(
      (rSlot: IOptionSlot): IOptionSlot => (rSlot.option.id === slot.option.id ? slot : rSlot),
    )

  // Clean up the option structure
  updatedSlots = cleanupSelectedSlotDays(updatedSlots)

  return updatedSlots
}

const getFilteredDaysByDate = <T>(selectedDays: T[], date: string): T[] =>
  selectedDays.filter((day: any): boolean => day.date !== date)

const updateSelectedDays = (days: IOptionSlotDay[], updatedDay: IOptionSlotDay): IOptionSlotDay[] =>
  days.map(
    (rDay: IOptionSlotDay): IOptionSlotDay => (rDay.date === updatedDay.date ? updatedDay : rDay),
  )

const filterSelectedSlots = (slots: IRoomTimeSlot[], slotId: number): IRoomTimeSlot[] =>
  slots.filter((rSlot: IRoomTimeSlot): boolean => rSlot.id !== slotId)

const cleanupSelectedSlotDays = (slots: IOptionSlot[]) =>
  slots.filter((rSlot: IOptionSlot): boolean => !!rSlot.selectedDays.length)

const cleanupSelectedDays = (days: IOptionSlotDay[]) =>
  days.filter((rDay: IOptionSlotDay): boolean => !!rDay.selectedSlots.length)

const removeSlotInOptions = ({
  date,
  slotId,
  options,
  optionId,
}: IRemoveSlotInOptionsProps): IOptionSlot[] => {
  let updatedOptions = options.map((option: IOptionSlot): IOptionSlot => {
    if (!optionId || optionId === option.option.id) {
      const hasOptionSameDay: IOptionSlotDay | undefined = option.selectedDays.find(
        (day: IOptionSlotDay): boolean => day.date === date,
      )

      if (hasOptionSameDay) {
        const selectedSlots = filterSelectedSlots(hasOptionSameDay.selectedSlots, slotId)

        const dayPrice = selectedSlots.reduce((total, slot) => total + slot.price, 0)

        let selectedDays = updateSelectedDays(option.selectedDays, {
          ...hasOptionSameDay,
          selectedSlots,
          price: dayPrice,
        })

        // Clean up the selected slots structure
        selectedDays = cleanupSelectedDays(selectedDays)

        const optionPrice = selectedDays.reduce((total, day) => total + day.price, 0)

        return { ...option, selectedDays, price: optionPrice }
      }
    }
    return option
  })

  // Clean up the selected days structure
  updatedOptions = cleanupSelectedSlotDays(updatedOptions)
  return updatedOptions
}

const getUpdatedDay = ({ slot, day, type }: IGetUpdatedDayProps): IOptionSlotDay => {
  let slots: IRoomTimeSlot[] = structuredClone<IRoomTimeSlot[]>(day.selectedSlots)
  const slotIndex = slots.findIndex((rSlot) => rSlot.id === slot.id)

  if (slotIndex === -1) slots = [...slots, slot]
  else if (type === 'remove') slots.splice(slotIndex, 1)
  else if (type === 'update') slots.splice(slotIndex, 1, slot)

  const updatedDayPrice = slots.reduce(
    (total, slot) => total + slot.price * (slot?.quantity || 1),
    0,
  )

  return {
    ...day,
    price: updatedDayPrice,
    selectedSlots: slots,
  }
}

const getUpdatedOption = ({
  slot,
  type,
  selectedDays,
  selectedDay: day,
}: IGetUpdatedOptionProps) => {
  let daysCopy: IOptionSlotDay[] = structuredClone<IOptionSlotDay[]>(selectedDays)
  const updatedDay: IOptionSlotDay = getUpdatedDay({ slot, day, type })

  const dayIndex = daysCopy.findIndex((rDay: IOptionSlotDay) => rDay.date === day.date)

  if (dayIndex === -1) daysCopy = [...daysCopy, updatedDay]
  else daysCopy.splice(dayIndex, 1, updatedDay)

  // Clean up the days structure
  daysCopy = cleanupSelectedDays(daysCopy)

  const optionPrice = daysCopy.reduce(
    (total: number, rDay: IOptionSlotDay) => total + rDay.price,
    0,
  )
  return {
    price: optionPrice,
    selectedDay: updatedDay,
    selectedDays: daysCopy,
  }
}

export const OptionService = {
  getUpdatedOption,
  getOptionSlotById,
  getFilteredDaysByDate,
  getUpdatedOptionSlots,
  removeSlotInOptions,
}
