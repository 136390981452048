import { Overlay } from 'common/components/Overlay'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { TOGGLE_IS_OPEN_CART_DETAILS } from 'features/Cart'
import { Option, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'

import styles from './optionOverlay.module.scss'

export const OptionOverlay = () => {
  const dispatch = useAppDispatch()
  const { isOpenOptionOverlay, overlayOption } = useAppSelector((state) => state.optionReducer)

  const handleSubmitOption = (): void => {
    handleCloseOverlay()
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }

  const handleCloseOverlay = (): void => {
    dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
  }

  return (
    <Overlay title={overlayOption?.name} onClose={handleCloseOverlay} isOpen={isOpenOptionOverlay}>
      <div className={styles.parent}>
        <Option option={overlayOption} handleCloseOverlay={handleSubmitOption} />
      </div>
    </Overlay>
  )
}
