export enum WIDGET_CONSTANTS {
  ACCOUNT = 'Account',
  POINTS = 'POINTS',
  SETTINGS = 'Account settings',
  CONDITIONS = 'Terms & Conditions',
  SIGN_OUT = 'Sign Out',
  PTS = ' pts.',
  PROFILE = 'Profile',
  READ_MORE_ABOUT_POINTS = 'Read more about points',
  VALIDATE_IDENTITY = 'Validate identity',
  SIGN_OUT_AS_DOCTOR = 'Sign Out as Doctor',
}
