import compose from 'compose-function'

import { withAuth } from 'app/hocs/withAuth'
import { withConnection } from 'app/hocs/withConnection'
import { withErrorBoundary } from 'app/hocs/withErrorBoundary'
import { withNotification } from 'app/hocs/withNotification'
import { withRouter } from 'app/hocs/withRouter'
import { withSentry } from 'app/hocs/withSentry'
import { withSitePoints } from 'app/hocs/withSitePoints'
import { withStore } from 'app/hocs/withStore'

export const withProviders = compose(
  withSentry,
  withErrorBoundary,
  withRouter,
  withStore,
  withNotification,
  withAuth,
  withSitePoints,
  withConnection,
)
