import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import moment from 'moment'

import { Calendar } from 'common/components/Calendar/Calendar'
import { UtilService } from 'common/services/utilService'

import { useGetPromotionCalendarQuery } from 'features/Promotions/state/api/promotionsApi'

type Props = {
  currentDate: moment.Moment
  startDate: string
  endDate: string
  handleChangeDate: (newDate: string) => void
}

const PromotionMonthView = ({ currentDate, startDate, endDate, handleChangeDate }: Props) => {
  const params = useParams()

  const [selectedMonth, setSelectedMonth] = useState(currentDate.format('YYYY-MM'))

  const leaseConnection = {
    leaseId: params?.leaseId,
    connection: params?.connection,
  }

  const { data, isLoading, isFetching } = useGetPromotionCalendarQuery({
    ...leaseConnection,
    date: selectedMonth,
  })

  const handleChangeMonth = (_: number, newMonth: string) => {
    const newMonthMoment = moment(newMonth, 'YYYY-MM')
    const startMoment = moment(startDate)
    const endMoment = moment(endDate)

    if (newMonthMoment.isBetween(startMoment, endMoment, 'month', '[]')) {
      setSelectedMonth(newMonth)
    }
  }

  useEffect(() => {
    setSelectedMonth(currentDate.format('YYYY-MM'))
  }, [currentDate])

  const days = UtilService.generateDatesWithValues(startDate, endDate, 1, false)

  const selectedDays = Object.keys(data ?? {}).filter((key) => data[key])

  return (
    <Calendar
      isStrictDisable
      days={days}
      withFakeRerender
      showCurrentDay={false}
      isLoading={isLoading || isFetching}
      handleChangeDate={handleChangeDate}
      handleChangeMonth={handleChangeMonth}
      selectedDays={selectedDays}
      currentDay={currentDate.format('YYYY-MM-DD')}
    />
  )
}

export default PromotionMonthView
