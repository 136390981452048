export enum SUPPORT_CONSTANTS {
  MY_TICKETS = 'My Tickets',
  NEW_TICKET_DESCRIPTION = "If you can't find a solution to your problem in our knowledgebase, you can submit a ticket by selecting the appropriate category below.",
  ALERT_TICKET_SUBMITTED = 'Ticket submitted',
  TICKETS = 'Tickets',
  TICKET_INFO = 'Ticket Info',
  TICKET_ID = 'Ticket ID',
  ADD_MESSAGE = 'Add message',
  TICKET_CATEGORY = 'Category',
  TICKET_CREATED = 'Created on',
  TICKET_UPDATED = 'Last update',
  MESSAGE = 'Message',
  TICKET_CLOSED = 'This ticket is closed',
}
