export enum DATE_FORMAT {
  CALENDAR_DATE = 'yyyy-MM-dd',
  CALENDAR_DATE_LEASE = 'YYYY-MM-DD',
  CREATE_LEASE = "ddd, MMM D, 'YY",
  SELECTED_DATE_LABEL = 'dddd, DD',
  DICTIONARY_DATE_FORMAT = 'MMM D, YYYY',
  TAB_PANEL_FORMAT = 'cccc, MMMM dd, yyyy',
  CLASSIC_FORMAT = 'MMMM d, yyyy',
  CALENDAR_DATE_TIME = 'yyyy-MM-dd HH:mm',
  DEFAULT_EXPIRY_DATE_FORMAT = 'M/yyyy',
  EXPIRY_DATE_FORMAT = 'MM/yy',
}
