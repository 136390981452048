import React, { useLayoutEffect, useState } from 'react'
import type { FC, JSX } from 'react'

import { Skeleton, type UploadFile } from 'antd'
import classNames from 'classnames'

import type { IUploadedFileSrc } from 'common/interfaces/IUploadedFileSrc'

import { SupportMessage } from 'features/Support/components/SupportMessage/SupportMessage'
import type { ITicketMessage } from 'features/Support/interfaces/ISupport'

import styles from './supportMessageList.module.scss'

interface IProps {
  isAddMessage: boolean
  isFetching: boolean
  messageList: ITicketMessage[]
  uploadedFiles: UploadFile[]
  handlePreviewFile: (file: IUploadedFileSrc) => void
}

export const SupportMessageList: FC<IProps> = ({
  messageList,
  isFetching,
  handlePreviewFile,
  isAddMessage,
  uploadedFiles,
}) => {
  const useWindowSize = (): number => {
    const [width, setWidth] = useState(0)
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setWidth(window.innerWidth)
      }

      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])

    return width
  }

  return (
    <div
      style={{
        height:
          useWindowSize() < 992
            ? '100vh'
            : isAddMessage && !uploadedFiles.length
            ? 'calc(100vh - 475px)'
            : !isAddMessage && !uploadedFiles.length
            ? 'calc(100vh - 215px)'
            : isAddMessage && uploadedFiles.length
            ? `calc(100vh - 475px - ${30 * uploadedFiles.length}px`
            : `calc(100vh - 215px - ${30 * uploadedFiles.length}px`,
      }}
      className={styles.parent}>
      {isFetching ? (
        <>
          <div className={styles.parentSkeletonItem}>
            <div className={styles.parentSkeletonHead}>
              <Skeleton paragraph={{ rows: 0 }} active />
            </div>
            <div className={styles.parentSkeletonContent}>
              <Skeleton paragraph={{ rows: 2 }} active />
            </div>
          </div>
          <div className={styles.parentSkeletonItem}>
            <div className={classNames(styles.parentSkeletonHead, styles.parentSkeletonHeadSecond)}>
              <Skeleton paragraph={{ rows: 0 }} active />
            </div>
            <div className={styles.parentSkeletonContent}>
              <Skeleton paragraph={{ rows: 1 }} active />
            </div>
          </div>
          <div className={styles.parentSkeletonItem}>
            <div className={styles.parentSkeletonHead}>
              <Skeleton paragraph={{ rows: 0 }} active />
            </div>
            <div className={styles.parentSkeletonContent}>
              <Skeleton paragraph={{ rows: 3 }} active />
            </div>
          </div>
        </>
      ) : (
        messageList?.map(
          (message: ITicketMessage): JSX.Element => (
            <React.Fragment key={message.id}>
              <SupportMessage message={message} handlePreviewFile={handlePreviewFile} />
            </React.Fragment>
          ),
        )
      )}
    </div>
  )
}
