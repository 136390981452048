import type { FC } from 'react'
import { memo, useCallback, useState } from 'react'
import type { Country, Value } from 'react-phone-number-input'
import PhoneInputWithCountrySelect, { parsePhoneNumber } from 'react-phone-number-input'

import { Label } from 'common/components/Label/Label'
import { PhoneInputSelect } from 'common/components/PhoneInput/PhoneInputSelect/PhoneInputSelect'
import { Show } from 'common/components/Show/Show'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'

import 'react-phone-number-input/style.css'
import './phoneInput.css'

interface IProps {
  value?: Value | string
  border?: boolean
  label?: string
  disabled?: boolean
  isLoading?: boolean
  placeholder?: string
  onChange?: (value: Value | null) => void
  onBlur?: () => void
  onResetCountry?: (resetFunc: () => void) => void
  error?: string
  required?: boolean
}

const PhoneInput: FC<IProps> = ({
  onChange,
  onBlur,
  onResetCountry = () => {},
  value,
  disabled = false,
  isLoading,
  placeholder,
  border = true,
  label,
  error = '',
  required,
}) => {
  const [defaultCountry, setDefaultCountry] = useState<Country>('US')
  const resetDefaultCountry = useCallback(() => {
    setDefaultCountry('US')
  }, [])

  onResetCountry(resetDefaultCountry)
  const handleChange = (number: Value): void => {
    if (number) onChange(number)
    else onChange(null)
  }

  const phoneNumber = parsePhoneNumber(value || '')

  const handleCountryChange = (code: Country): void => {
    setDefaultCountry(code || defaultCountry)
  }
  return (
    <>
      {label && <Label label={label} required={required} />}
      <PhoneInputWithCountrySelect
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
        addInternationalOption={false}
        defaultCountry={defaultCountry}
        disabled={disabled || isLoading}
        value={phoneNumber?.number || value}
        onCountryChange={handleCountryChange}
        className={!border ? 'PhoneInputBorderOff' : ''}
        countrySelectComponent={(data: object) => (
          <PhoneInputSelect data={data} isLoading={isLoading} country={defaultCountry} />
        )}
      />

      <Show when={error}>
        <p className='errorMessage'>{error}</p>
      </Show>
    </>
  )
}

// @ts-ignore: Mini hack for prevent dropdown closing
export default memo(
  PhoneInput,
  (prevProps: Readonly<IProps>, nextProps: Readonly<IProps>): boolean => {
    const isDiffValue: boolean = prevProps.value !== nextProps.value
    const isDiffDisabled: boolean = prevProps.disabled !== nextProps.disabled
    const isDiffIsLoading: boolean = prevProps.isLoading !== nextProps.isLoading
    const isDiffError: boolean = prevProps.error !== nextProps.error

    const rerenderDeps: boolean[] = [isDiffValue, isDiffDisabled, isDiffIsLoading, isDiffError]

    if (rerenderDeps.includes(true)) return false
    return true
  },
)
