import type { FC } from 'react'

import previewIcon from 'common/assets/icons/eye.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import { SupportService } from 'features/Support/services/supportService'

import styles from './supportAttachment.module.scss'

const { getTruncatedFileName } = SupportService

interface IProps {
  name: string
  handlePreviewFile: () => void
}

export const SupportAttachment: FC<IProps> = ({ name, handlePreviewFile }) => {
  return (
    <div className={styles.parent}>
      {getTruncatedFileName(name)}
      <button onClick={handlePreviewFile} className={styles.parentPreview}>
        <img src={previewIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
      </button>
    </div>
  )
}
