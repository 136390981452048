import type { FC } from 'react'

import classNames from 'classnames'

import trashIcon from 'common/assets/icons/trash-secondary.svg'
import trashWhiteIcon from 'common/assets/icons/trash-white.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_MODIFIER, BUTTON_PROPORTION } from 'common/constants/buttonConstants'

import type { IRoomTimeSlot } from 'features/Lease/interfaces/ILeaseInfoRoom'

import styles from './leaseSlot.module.scss'

interface IProps {
  slot: IRoomTimeSlot
  isSelectedSlot: boolean
  isConfirmedSlot: boolean
  currentSelected: IRoomTimeSlot | null
  handleConfirmSlot?: (slot: IRoomTimeSlot) => void
  handleRemoveTimeSlot: (slot: IRoomTimeSlot) => void
  handleSelectActiveSlot?: (slot: IRoomTimeSlot) => void
}

export const LeaseSlot: FC<IProps> = ({
  slot,
  currentSelected,
  isSelectedSlot,
  isConfirmedSlot,
  handleConfirmSlot,
  handleRemoveTimeSlot,
  handleSelectActiveSlot,
}) => {
  return (
    <div className={styles.parent}>
      <div className={styles.parentContainer}>
        {!isConfirmedSlot && !isSelectedSlot ? (
          <Button
            data-cy='roomInfo-slot'
            proportion={BUTTON_PROPORTION.LARGE}
            modifier={BUTTON_MODIFIER.SECONDARY}
            disabled={!Boolean(slot.is_active)}
            onClick={() => {
              handleConfirmSlot(slot)
            }}>
            <div
              className={classNames(styles.parentContent, {
                [styles.parentContentDisabled]: !Boolean(slot.is_active),
              })}>
              <span className={styles.parentPrice}>${slot.price}</span>
              {slot.title}
            </div>
          </Button>
        ) : (
          !isSelectedSlot && (
            <Button proportion={BUTTON_PROPORTION.LARGE}>
              <div
                className={classNames(styles.parentContent, {
                  [styles.parentContentDisabled]: !Boolean(slot.is_active),
                })}>
                <span className={styles.parentPrice}>${slot.price}</span>
                {slot.title}
              </div>
            </Button>
          )
        )}
        <Show when={isSelectedSlot}>
          <button
            data-cy={`delete-slot-${currentSelected?.id}`}
            onClick={() => handleSelectActiveSlot(slot)}
            className={classNames(styles.parentSelectedSlot, {
              [styles.parentSelectedSlotActive]: currentSelected?.id === slot?.id,
            })}>
            <img
              alt={ALT_CONSTANTS.ACTION_ICON}
              src={currentSelected?.id === slot?.id ? trashWhiteIcon : trashIcon}
              onClick={(clickEvent: React.MouseEvent<HTMLSpanElement>) => {
                clickEvent.stopPropagation()
                handleRemoveTimeSlot(slot)
              }}
            />
            {slot.title}
          </button>
        </Show>
      </div>
    </div>
  )
}
