import { Skeleton } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './leaseSlotSkeletonList.module.scss'

export const LeaseSlotSkeletonList = () => (
  <ArrayElements total={5}>
    <Skeleton.Button className={styles.parent} active block />
  </ArrayElements>
)
