import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import type { IOption } from 'common/interfaces/IOption'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { IInfoLease, ILeaseNotification } from 'features/Info/interfaces/IInfo'
import type {
  IInfoPatientSchedule,
  IPatientRoom,
  IPatientSlot,
} from 'features/Info/interfaces/IInfoPatient'
import type { IPatientSchedule, IPatientStatus } from 'features/Info/interfaces/IInfoSchedule'
import type { IInfoShopItem } from 'features/Info/interfaces/IInfoShop'
import type { INFO_SHOP_ITEM_TYPE } from 'features/Info/types/infoShopType'

const { withPaginationParams } = UtilService

export const infoApi = createApi({
  reducerPath: REDUCER_CONSTANTS.INFO,
  baseQuery: baseQuery(),
  tagTypes: ['LeaseDetails', 'PatientSchedule'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchLeaseStatus: build.query({
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/status`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ status }: { status: string }): string =>
        UtilService.capitalizeFirstLetter(status),
    }),
    fetchLeaseInfo: build.query({
      providesTags: ['LeaseDetails', 'PatientSchedule'],
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ item }: Record<string, any>): IInfoLease => {
        return {
          ...item,
          status: UtilService.capitalizeFirstLetter(item.status),
          ...(item.lease_days && { lease_days: UtilService.sortTabPanelDays(item.lease_days) }),
        }
      },
    }),
    fetchLeaseDocuments: build.query({
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/docs`,
        headers: { 'Database-Connection': connection },
      }),
    }),

    fetchLeaseShop: build.query({
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/shop`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: (response: IInfoShopItem[]): IInfoShopItem[] =>
        response.map((item: IInfoShopItem) => ({
          ...item,
          type: UtilService.capitalizeFirstLetter(item.type) as INFO_SHOP_ITEM_TYPE,
        })),
    }),

    fetchLeaseStaff: build.query({
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/staff`,
        headers: { 'Database-Connection': connection },
      }),
    }),

    fetchLeaseStaffOnAdd: build.query({
      query: ({ connection, leaseId }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/staff/create`,
        headers: { 'Database-Connection': connection },
      }),
    }),

    fetchLeaseSlots: build.query({
      query: ({
        connection,
        leaseId,
        date,
      }: {
        connection: string
        leaseId: string
        date: string
      }) => ({
        url: `/leases/${leaseId}/day/${date}`,
        headers: { 'Database-Connection': connection },
      }),
    }),
    updateLeaseMedia: build.mutation({
      query: ({ screen, file, leaseId, connection }) => ({
        url: `/leases/${leaseId}/media`,
        data: {
          file,
          display_number: screen,
        },
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection, 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: ['LeaseDetails'],
    }),
    updateLeaseMusic: build.mutation({
      query: ({ ambient_music_id, leaseId, connection }) => ({
        url: `/leases/${leaseId}/ambient-music`,
        data: { ambient_music_id },
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection, 'Content-Type': 'multipart/form-data' },
      }),
    }),
    removeLeaseMedia: build.mutation({
      query: ({ mediaId, leaseId, connection }) => ({
        url: `/leases/${leaseId}/media/${mediaId}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': connection },
      }),
      invalidatesTags: ['LeaseDetails'],
    }),

    fetchPatientActivityDays: build.query({
      query: ({ leaseId, connection }: { connection: string; leaseId: string }) => ({
        url: `/leases/${leaseId}/days`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ days }: { days: IOption[] }): IOption[] => days,
    }),

    fetchPatientActivity: build.query({
      query: ({
        date,
        connection,
        leaseId,
      }: {
        date: string
        leaseId: string
        connection: string
      }) => ({
        url: `/leases/${leaseId}/day/${date}/patients`,
        headers: { 'Database-Connection': connection },
      }),
      providesTags: ['PatientSchedule'],
      transformResponse: (response: any): IInfoPatientSchedule => {
        const getAdjustedRooms = (
          rooms: {
            code: string
            name: string
            time_slots: IPatientSlot[]
            max_patients: number
            status: IPatientStatus
            id?: number
            is_waiting_room: boolean | undefined
          }[],
        ): IPatientRoom[] =>
          rooms.map(
            (option: {
              code: string
              name: string
              time_slots: IPatientSlot[]
              max_patients: number
              status: IPatientStatus
              id?: number
              is_waiting_room: boolean
            }) => ({
              value: option.code,
              label: option.name,
              slots: option.time_slots,
              max_patients: option.max_patients,
              status: option.status,
              id: option.id,
              is_waiting_room: option.name === 'Waiting Room',
            }),
          )

        return {
          ...response,
          rooms: getAdjustedRooms(response.rooms),
          items: response.items.map((schedule: any): IPatientSchedule => {
            const rooms_list = getAdjustedRooms(response.rooms)
            const room: IPatientRoom | undefined = rooms_list.find(
              (room: IPatientRoom): boolean => room.value === schedule.room,
            )
            return {
              ...schedule,
              room,
            }
          }),
        }
      },
    }),

    createPatientSchedule: build.mutation({
      query: ({ date, connection, leaseId, data }) => ({
        data,
        url: `/leases/${leaseId}/day/${date}/appointment`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
      invalidatesTags: ['PatientSchedule'],
    }),

    deletePatientSchedule: build.mutation({
      query: ({ date, connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/day/${date}/appointment/${id}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': connection },
      }),
    }),

    notifyPatient: build.mutation({
      query: ({ connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/notify-patient/${id}`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),

    updatePatientSchedule: build.mutation({
      query: ({ date, connection, leaseId, id, data }) => ({
        data,
        url: `/leases/${leaseId}/day/${date}/appointment/${id}`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': connection },
      }),
    }),
    fetchProcedures: build.query({
      query: ({ search, connection }: { search: string; connection: string }) => ({
        url: `leases/appointment/procedures/list?search=${search}`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ items }: { items: string }): string => items,
    }),

    sendPatientsNotification: build.mutation({
      query: ({ connection, leaseId, data }) => ({
        data,
        url: `/leases/${leaseId}/send-notification`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),

    updateRoomStatus: build.mutation({
      query: ({ connection, roomId, data }) => ({
        data,
        url: `/room/${roomId}/status`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': connection },
      }),
    }),

    updateSelectPatients: build.mutation({
      query: ({ connection, roomId, data }) => ({
        data,
        url: `/room/${roomId}/appointments`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),

    addLeaseStaff: build.mutation({
      query: ({ connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/staff`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
        data: { id },
      }),
    }),

    deleteLeaseStaff: build.mutation({
      query: ({ connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/staff/${id}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': connection },
        data: { id },
      }),
    }),
    resetCode: build.query({
      query: ({ connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/reset-code/${id}`,
        headers: { 'Database-Connection': connection },
      }),
    }),

    resendCode: build.query({
      query: ({ connection, leaseId, id }) => ({
        url: `/leases/${leaseId}/send-code/${id}`,
        headers: { 'Database-Connection': connection },
      }),
    }),

    downloadContract: build.query({
      query: ({ connection, leaseId, type = 'contract' }) => ({
        url: `leases/${leaseId}/download/${type}`,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Database-Connection': connection,
          'Content-Type': 'application/pdf',
        },
      }),
    }),

    terminateLease: build.mutation({
      query: ({ id, connection }) => ({
        url: `/leases/${id}/terminate-lease`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),

    fetchStorageLeaseNotifications: build.query({
      query: ({ leaseId, connection, params }) => ({
        url: withPaginationParams(`/leases/${leaseId}/notifications`, params),
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: (
        response: IRequestData<ILeaseNotification[]>,
      ): IRequestData<ILeaseNotification[]> => ({
        ...response,
      }),
    }),
  }),
})

export const {
  useLazyResetCodeQuery,
  useLazyResendCodeQuery,
  useFetchLeaseShopQuery,
  useFetchLeaseStaffQuery,
  useLazyFetchLeaseStaffOnAddQuery,
  useFetchLeaseInfoQuery,
  useFetchLeaseStatusQuery,
  useFetchLeaseDocumentsQuery,
  useLazyFetchLeaseSlotsQuery,
  useUpdateLeaseMediaMutation,
  useRemoveLeaseMediaMutation,
  useUpdateLeaseMusicMutation,
  useLazyDownloadContractQuery,
  useLazyFetchPatientActivityQuery,
  useFetchPatientActivityDaysQuery,
  useLazyFetchProceduresQuery,
  useCreatePatientScheduleMutation,
  useDeletePatientScheduleMutation,
  useNotifyPatientMutation,
  useUpdatePatientScheduleMutation,
  useSendPatientsNotificationMutation,
  useUpdateRoomStatusMutation,
  useUpdateSelectPatientsMutation,
  useDeleteLeaseStaffMutation,
  useAddLeaseStaffMutation,
  useTerminateLeaseMutation,
  useLazyFetchStorageLeaseNotificationsQuery,
}: any = infoApi
