import type { ComponentType, FC } from 'react'

import { BrowserTracing, init, Replay } from '@sentry/react'

export const withSentry = <T extends object>(Component: ComponentType<T>): FC<T> => {
  const HOST_NAME = 'residen.com'
  if (window.location.hostname === HOST_NAME) {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [/\.*residen\.com/],
        }),
        new Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }

  const WithSentryWrapper: FC<T> = (props: T) => {
    return <Component {...props} />
  }

  return WithSentryWrapper
}
