export enum TABLE_COLUMN_TYPES {
  TEXT = 'text',
  SELECT = 'select',
  NUMBER = 'number',
  FILE = 'file',
  EMAIL = 'email',
  TIME_PICKER_SLOTS = 'time-picker-slots',
  CHECKBOX = 'checkbox',
  PHONE = 'phone',
  AUTOCOMPLETE = 'autocomplete',
}

export enum TABLE_ACTIONS {
  EDIT = 'edit',
  DELETE = 'delete',
  SEND_CODE = 'send-code',
  RESET_CODE = 'reset-code',
  DOWNLOAD_CONTRACT = 'download-contract',
  LOGIN_AS = 'login-as',
}

export enum TABLE_VALIDATION_ERRORS {
  MIN_CHARACHTERS = 'Minimum 2 letters required!',
}
