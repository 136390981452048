import type { FC } from 'react'

import classNames from 'classnames'

import type { TAG_STATUS } from 'common/types/tagStatusType'

// I suppress next line with eslint-disable-next-line because i use dynamic class
// eslint-disable-next-line css-modules/no-unused-class
import styles from './tagStatus.module.scss'

interface IProps {
  status: TAG_STATUS
  isLarge?: boolean
  isDark?: boolean
}

export const TagStatus: FC<IProps> = ({ status, isLarge, isDark }) => {
  const convertToCamelCase = (inputString: string): string => {
    if (!inputString) return

    const words = inputString.split(' ')

    const capitalizedWords = words.map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )

    const camelCaseString = capitalizedWords.join('')

    return camelCaseString
  }

  return (
    <div
      className={classNames(styles.parent, styles[`parent${convertToCamelCase(status)}`], {
        [styles.parentLarge]: isLarge,
        [styles.parentDark]: isDark,
      })}>
      {status}
    </div>
  )
}
