import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'

interface IProps {
  connection: string
  setConnection: Dispatch<SetStateAction<string>>
}

const ConnectionContext = createContext<IProps | null>(null)

export const ConnectionProvider = ({ children }: { children: ReactNode }) => {
  const [connection, setConnection] = useState<string>(null)
  return (
    <ConnectionContext.Provider value={{ connection, setConnection }}>
      {children}
    </ConnectionContext.Provider>
  )
}

export const useConnection = () => {
  return useContext(ConnectionContext) as IProps
}
