import type { FC } from 'react'

import { FormAutocomplete } from 'common/components/FormItems/FormAutocomplete/FormAutocomplete'
import { FormCheckbox } from 'common/components/FormItems/FormCheckbox/FormCheckbox'
import { FormInput } from 'common/components/FormItems/FormInput/FormInput'
import { FormPassword } from 'common/components/FormItems/FormInput/FormPassword'
import FormPhone from 'common/components/FormItems/FormPhone/FormPhone'
import { FormRadio } from 'common/components/FormItems/FormRadio/FormRadio'
import { FormRecaptcha } from 'common/components/FormItems/FormRecaptcha/FormRecaptcha'
import { FormSelect } from 'common/components/FormItems/FormSelect/FormSelect'
import { FormSwitch } from 'common/components/FormItems/FormSwitch/FormSwitch'
import { FormTextarea } from 'common/components/FormItems/FormTextarea/FormTextarea'
import { FormTimePickerSlots } from 'common/components/FormItems/FormTimePickerSlots/FormTimePickerSlots'
import { FormUpload } from 'common/components/FormItems/FormUpload/FormUpload'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'
import type { IFormControl } from 'common/interfaces/IFormItem'

export const FormControl: FC<IFormControl> = ({
  placeholder,
  name,
  additional,
  control,
  style,
  options,
  label,
  type,
  onChange,
  ...rest
}) => {
  switch (control) {
    case FORM_CONTROL_TYPE.CHECKBOX:
      return <FormCheckbox {...rest} name={name} label={label} />

    case FORM_CONTROL_TYPE.SWITCH:
      return <FormSwitch name={name} label={label} {...rest} />

    case FORM_CONTROL_TYPE.SELECT:
      return (
        <FormSelect
          {...rest}
          name={name}
          label={label}
          listOptions={options}
          placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
          onChange={onChange}
        />
      )

    case FORM_CONTROL_TYPE.RADIO:
      return <FormRadio name={name} label={label} style={style} options={options as string[]} />

    case FORM_CONTROL_TYPE.INPUT:
      return (
        <FormInput
          {...rest}
          name={name}
          type={type}
          label={label}
          onChange={onChange}
          placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
        />
      )

    case FORM_CONTROL_TYPE.PASSWORD_INPUT:
      return (
        <FormPassword
          {...rest}
          name={name}
          type={type}
          label={label}
          onChange={onChange}
          placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
        />
      )

    case FORM_CONTROL_TYPE.TEXTAREA:
      return <FormTextarea {...rest} name={name} label={label} />

    case FORM_CONTROL_TYPE.UPLOAD:
      return <FormUpload label={label} name={name} iconSrc={additional} {...rest} />

    case FORM_CONTROL_TYPE.AUTOCOMPLETE:
      return (
        <FormAutocomplete
          {...rest}
          label={label}
          name={name}
          options={options}
          onChange={onChange}
        />
      )

    case FORM_CONTROL_TYPE.RECAPTCHA:
      return <FormRecaptcha name={name} />

    case FORM_CONTROL_TYPE.PHONE:
      return (
        <FormPhone
          {...rest}
          name={name}
          label={label}
          placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
        />
      )

    case FORM_CONTROL_TYPE.TIME_PICKER_SLOTS:
      return (
        <FormTimePickerSlots
          {...rest}
          label={label}
          name={name}
          options={options}
          onChange={onChange}
        />
      )

    default:
      return null
  }
}
