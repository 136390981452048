import { createSearchParams, useNavigate } from 'react-router-dom'

const useNavigateParams = () => {
  const navigate = useNavigate()

  return (pathname: string | number, params?: Record<string, string | string[]>) => {
    if (typeof pathname === 'number') {
      return navigate(pathname)
    }
    const path = {
      pathname,
      search: createSearchParams(params).toString(),
    }

    navigate(path)
  }
}

export default useNavigateParams
