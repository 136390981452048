import type { FC } from 'react'
import { useId } from 'react'

import { Input as AntInput, Skeleton } from 'antd'
import classNames from 'classnames'

import { Label } from 'common/components/Label/Label'
import { Show } from 'common/components/Show/Show'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'
import type { IInput } from 'common/interfaces/IInput'

import styles from './input.module.scss'

export const PasswordInput: FC<IInput> = ({ label, isLoading, required, placeholder, ...rest }) => {
  const id = useId()
  return (
    <div className={styles.parent}>
      <Label id={id} required={required} label={label} />
      <div
        className={classNames(styles.container, {
          [styles.containerSkeleton]: isLoading,
        })}>
        <Show
          when={isLoading}
          fallback={
            <AntInput.Password
              id={id}
              placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
              {...rest}
            />
          }>
          <Skeleton paragraph={{ rows: 0 }} active />
        </Show>
      </div>
    </div>
  )
}
