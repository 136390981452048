import type { FC, JSX } from 'react'
import { Fragment, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { CartShopItem } from 'features/Cart/components/CartShopItem/CartShopItem'
import type { IOptionShopCartOption } from 'features/Option'
import type { IOptionShopCartItem } from 'features/Option'
import { OptionShopService } from 'features/Option'
import { useShopCartManager } from 'features/Option/hooks/useShopCartManager'
import { UPDATE_OPTION_SHOP_ITEMS } from 'features/Option/state/slices/optionSlice'

interface IProps {
  leaseId: string
  handleEdit: (option: ISiteRoom) => void
  options: IOptionShopCartOption[] | undefined
}

const { removeSlotFromShopItems } = OptionShopService

export const CartShopServiceList: FC<IProps> = ({ options, leaseId, handleEdit }) => {
  const { updateShopCartContent } = useShopCartManager()
  const dispatch = useAppDispatch()
  const { optionShopItems: shopItems } = useAppSelector((state) => state.optionReducer)

  const handleRemoveSlot = useCallback(
    (props: { optionId: number; roomId: number; slotId: number }): void => {
      const updatedItems: IOptionShopCartItem[] = removeSlotFromShopItems({
        ...props,
        shopItems,
        leaseId,
      })

      dispatch(UPDATE_OPTION_SHOP_ITEMS(updatedItems))

      updateShopCartContent(updatedItems)
    },
    [shopItems],
  )

  if (!options) return null

  return (
    <>
      {options.map(
        (shopOption: IOptionShopCartOption, index: number): JSX.Element => (
          <Fragment key={index}>
            <CartShopItem
              shopOption={shopOption}
              handleEdit={handleEdit}
              handleRemoveSlot={(roomId: number, slotId: number) =>
                handleRemoveSlot({ optionId: shopOption.option.id, roomId, slotId })
              }
            />
            <hr />
          </Fragment>
        ),
      )}
    </>
  )
}
