import type { FC } from 'react'
import { memo, useState } from 'react'

import { Tooltip } from 'antd'

import { useScreenWidth } from 'app'

import addLeaseHoveredIcon from 'common/assets/icons/add-lease-hovered.svg'
import addLeaseIcon from 'common/assets/icons/add-lease.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import { HOME_CONSTANTS } from 'features/Home/constants/homeConstants'

import styles from './leaseButton.module.scss'

import 'antd/lib/tooltip/style/css'

interface IProps {
  handleOpenLease: () => void
}

export const LeaseButton: FC<IProps> = memo(({ handleOpenLease }) => {
  const [addLeaseImage, setAddLeaseImage] = useState(addLeaseIcon)
  const { isMobile } = useScreenWidth()
  return (
    <div className={styles.addNewLease}>
      <Tooltip title={isMobile ? null : HOME_CONSTANTS.NEW_LEASE}>
        <button
          onClick={handleOpenLease}
          className={styles.addNewLeaseButton}
          onMouseLeave={() => setAddLeaseImage(addLeaseIcon)}
          onMouseOver={() => setAddLeaseImage(addLeaseHoveredIcon)}
          data-cy='new-lease-btn'>
          <img src={addLeaseImage} alt={ALT_CONSTANTS.ACTION_ICON} />
        </button>
        <p className={styles.addNewLeaseText}>BOOK</p>
      </Tooltip>
    </div>
  )
})
