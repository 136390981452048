export enum ELeases {
  Booking = 'booking',
  Mailbox = 'mailbox',
  Storage = 'storage',
  Recurring = 'recurring',
}

export const LeasesTypeArr = Object.values(ELeases)

export enum ELeaseParams {
  Type = 'type',
  BookingId = 'bookingId',
}
export type LeaseParamsType = {
  type: ELeases
  bookingId?: string
}

export enum ELeasTypeCode {
  booking = 1,
  mailbox = 2,
  storage = 6,
}

export const getLeaseTypeFromCode = (code: number): ELeases => {
  switch (code) {
    case ELeasTypeCode.booking:
      return ELeases.Booking
    case ELeasTypeCode.mailbox:
      return ELeases.Mailbox
    case ELeasTypeCode.storage:
      return ELeases.Storage
    default:
      return ELeases.Recurring
  }
}
