import type { SorterResult } from 'antd/lib/table/interface'

import type { SortsType } from 'common/interfaces/ITable'

export const formatSorters = (sorter: SorterResult<any> | SorterResult<any>[]) => {
  const sorterArray = Array.isArray(sorter) ? sorter : [sorter]
  const sortObject = sorterArray.reduce((cum, sorter) => {
    if (!sorter.order) {
      return cum
    }
    const multiple = typeof sorter.column?.sorter === 'object' ? sorter.column.sorter.multiple : -1
    return {
      ...cum,
      [sorter.columnKey as string]: { order: sorter.order, multiple },
    }
  }, {})
  return sortObject as any
}

export const formatSortersAsParams = (sorters: SortsType) => {
  const sortArray = Object.keys(sorters)
    .map((key) => ({
      key,
      ...sorters[key],
    }))
    .sort((a, b) => (a.multiple || 0) - (b.multiple || 0))

  const performedArr = sortArray.map((item) => {
    const sign = item.order === 'descend' ? '-' : ''
    return sign + item.key
  })
  return performedArr.join(',')
}
