import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { ErrorMessage, Field, useField } from 'formik'

import { Select } from 'common/components/Select/Select'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import type { ISelect } from 'common/interfaces/ISelect'

import styles from './formSelect.module.scss'

export const FormSelect: FC<ISelect> = ({
  name,
  mode,
  value,
  label,
  listOptions,
  onChange,
  ...rest
}) => {
  const [field, _, helpers] = useField(name)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (value && value !== field.value) helpers.setValue(value)
  }, [value])

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false)
    }

    const modal = document.querySelector('.ant-modal-wrap')
    const elementToCheck = modal || window

    elementToCheck.addEventListener('scroll', handleScroll)
    return () => {
      elementToCheck.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={styles.parent}>
      <Field
        mode={mode}
        id={name}
        name={name}
        showSearch
        label={label}
        component={Select}
        listOptions={listOptions}
        data-cy={`${name}-form-control`}
        as={FORM_CONTROL_TYPE.SELECT}
        open={open}
        onDropdownVisibleChange={(isOpen: boolean) => setOpen(isOpen)}
        value={field.value || null}
        onChange={(values: number[] | number, option: any) => {
          helpers.setValue(values)
          if (onChange) onChange(values, option)
        }}
        {...rest}
      />

      <ErrorMessage
        name={name}
        className={styles.parentErrorInform}
        render={(msg) => <p className={styles.parentErrorInform}>{msg}</p>}
      />
    </div>
  )
}
