import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { IRoomOptionProduct, IRoomTimeSlot } from 'features/Lease/interfaces/ILeaseInfoRoom'

export const leaseApi = createApi({
  reducerPath: REDUCER_CONSTANTS.LEASE,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchRoomSlots: build.query({
      query: ({ roomId, date, connection }) => ({
        url: `/lease/room/${roomId}/day-slots/${date}`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ slots }: { slots: IRoomTimeSlot[] }): IRoomTimeSlot[] => {
        const getServiceOptions = (services: IRoomOptionProduct[]) => {
          const options: IRoomOptionProduct[] = services.filter(
            ({ type }: IRoomOptionProduct): boolean => type !== 'service',
          )

          const filteredServices: IRoomOptionProduct[] = services.filter(
            ({ type }: IRoomOptionProduct): boolean => type === 'service',
          )

          return {
            options,
            services: filteredServices,
          }
        }

        return slots.map((slot: IRoomTimeSlot): IRoomTimeSlot => {
          if (!slot?.options) return slot
          const { options, services } = getServiceOptions(slot.options.services)
          return {
            ...slot,
            options: {
              services: services,
              products: [...options, ...slot.options.products],
              provision_packages: slot.options.provision_packages,
            },
          }
        })
      },
    }),
    fetchRoomDays: build.query({
      query: ({ id, date, connection }) => ({
        url: `/lease/room/${id}/calendar/${date}`,
        headers: { 'Database-Connection': connection },
      }),
    }),
  }),
})

export const { useLazyFetchRoomSlotsQuery, useLazyFetchRoomDaysQuery }: any = leaseApi
