/* eslint-disable css-modules/no-unused-class */
import type { FC, ReactNode } from 'react'
import { memo } from 'react'
import { useToggle } from 'react-use'

import classNames from 'classnames'

import trashIcon from 'features/Cart/assets/trash.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { UtilService } from 'common/services/utilService'

import { CartError } from 'features/Cart/components/CartError/CartError'
import { CartErrorList } from 'features/Cart/components/CartErrorList/CartErrorList'

import styles from './cartCollapse.module.scss'

interface IProps {
  error?: string
  title: string
  errorList?: string[]
  children: ReactNode
  additionalActiveTitle?: string
  handleRemoveSlot?: () => void
}

export const CartCollapse: FC<IProps> = memo(
  ({ title, error, children, errorList, handleRemoveSlot }) => {
    const [detailsStatus, toggleDetailsStatus] = useToggle(false)

    return (
      <div className={styles.parent}>
        <div className={styles.parentContent}>
          <details
            className={styles.parentDetails}
            open={detailsStatus}
            onToggle={toggleDetailsStatus}>
            <summary
              className={classNames(styles.parentSummary, {
                [styles.parentSummaryError]: errorList || error,
              })}>
              <span title={title} className={styles.parentTitle}>
                {title}
                {/* <Show when={detailsStatus && additionalActiveTitle}>
                  <p className={styles.parentSlot}>{additionalActiveTitle}</p>
                </Show> */}
              </span>
            </summary>
            <Show when={children}>
              <div className={styles.parentOptions}>{children}</div>
            </Show>
          </details>

          <Show when={handleRemoveSlot}>
            <button
              className={styles.parentRemoveButton}
              onClick={handleRemoveSlot}
              data-cy={`delete-${UtilService.replaceSpaceToUnderscore(title)}`}>
              <img
                src={trashIcon}
                alt={ALT_CONSTANTS.ACTION_ICON}
                className={styles.parentRoomRemoveIcon}
              />
            </button>
          </Show>
        </div>
        <CartError error={error} />
        <CartErrorList errorList={errorList} />
      </div>
    )
  },
)
