import { createSlice } from '@reduxjs/toolkit'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'

import { SMS_STATUSES } from 'features/Info/constants/infoConstants'
import type { IInfoSlice } from 'features/Info/state/interface/IInfoSlice'

const initialState: IInfoSlice = {
  rooms: [],
  schedules: [],
  patients: [],
  languages: [],
  consultation_staffs: [],
  doctorsBufferTime: [],
  room_statuses: [],
  patient_statuses: [],
  isFetching: false,
  isUpdating: false,
  isUpdatingRoomStatus: false,
  isSendingNotifications: false,
  activeTabKey: {},
  skipPusherEvents: false,
  eventsToSendNotification: [],
  disabledIntervals: [],
  dayInterval: {
    min: 8,
    max: 22,
  },
  timeZone: '',
  isDragging: false,
  patientsSelection: {
    room: null,
    patients: [],
    isPatientSelectionVisible: false,
  },
  updatedEvents: null,
}

export const infoSlice = createSlice({
  name: REDUCER_CONSTANTS.INFO,
  initialState: initialState,
  reducers: {
    UPDATE_INFO_ROOMS(state, action) {
      state.rooms = action.payload
    },

    UPDATE_INFO_SCHEDULES(state, action) {
      state.schedules = action.payload
    },

    UPDATE_INFO_PATIENTS(state, action) {
      state.patients = action.payload
    },

    UPDATE_INFO_PATIENTS_SELECTION(state, action) {
      state.patientsSelection = action.payload
    },

    UPDATE_INFO_PATIENTS_LANGUAGES(state, action) {
      state.languages = action.payload
    },

    UPDATE_INFO_PATIENTS_CONSULTATION_STAFFS(state, action) {
      state.consultation_staffs = action.payload
    },

    UPDATE_INFO_DOCTORS_BUFFER_TIME(state, action) {
      state.doctorsBufferTime = action.payload
    },

    UPDATE_INFO_ROOM_STATUSES(state, action) {
      state.room_statuses = action.payload
    },

    UPDATE_INFO_PATIENT_STATUSES(state, action) {
      state.patient_statuses = action.payload
    },

    INSERT_INFO_SCHEDULE(state, action) {
      state.schedules.push(action.payload)
    },

    UPDATE_INFO_IS_FETCHING(state, action) {
      state.isFetching = action.payload
    },

    UPDATE_INFO_IS_UPDATING(state, action) {
      state.isUpdating = action.payload
    },

    UPDATE_INFO_ACTIVE_TAB_KEY(state, action) {
      const { key, value } = action.payload
      state.activeTabKey[key] = value
    },

    UPDATE_INFO_TIME_ZONE(state, action) {
      state.timeZone = action.payload
    },

    REMOVE_INFO_SCHEDULE(state, action) {
      state.schedules = state.schedules.filter((schedule) => schedule.id !== action.payload)
    },

    UPDATE_INFO_SCHEDULE(state, action) {
      const schedule = action.payload
      const index = state.schedules.findIndex((elm) => elm.id === schedule.id)
      state.schedules[index] = schedule
    },

    UPDATE_INFO_SKIP_PUSHER_EVENTS(state, action) {
      state.skipPusherEvents = action.payload
    },

    UPDATE_INFO_SCHEDULE_LOCATION(state, action) {
      const { scheduleId, location } = action.payload
      const index = state.schedules.findIndex((schedule) => schedule.id === scheduleId)
      if (index > -1) {
        state.schedules[index].current_location = location.name
      }
    },

    UPDATE_INFO_SCHEDULE_STATUS(state, action) {
      const { scheduleId, status, status_text, temperature } = action.payload
      const index = state.schedules.findIndex((schedule) => schedule.id === scheduleId)
      if (index > -1) {
        state.schedules[index].status = status
        state.schedules[index].notes = status_text
        state.schedules[index].temperature = temperature
      }
    },

    UPDATE_INFO_EVENTS_TO_SEND_NOTIFICATION(state, action) {
      state.eventsToSendNotification = action.payload
    },

    INSERT_INFO_EVENTS_TO_SEND_NOTIFICATION(state, action) {
      const index = state.eventsToSendNotification.findIndex((id: number) => id === action.payload)
      if (index === -1) {
        state.eventsToSendNotification.push(action.payload)
      }
    },

    UPDATE_INFO_ROOM_STATUS(state, action) {
      const { roomId, status } = action.payload
      const updatedRooms = state.rooms.map((room) => {
        if (room.id === roomId) {
          return {
            ...room,
            status: status,
          }
        }
        return room
      })
      state.rooms = updatedRooms
    },

    SET_IS_UPDATING_ROOM_STATUS(state, action) {
      state.isUpdatingRoomStatus = action.payload
    },

    UPDATE_INFO_DISABLED_INTERVALS(state, action) {
      state.disabledIntervals = action.payload
    },

    UPDATE_INFO_SEND_NOTIFICATIONS(state, action) {
      state.isSendingNotifications = action.payload
    },

    UPDATE_STATUSES_TO_PENDING(state, action) {
      const appointments = action.payload
      state.schedules = state.schedules.map((schedule) => {
        if (appointments.includes(schedule.id)) {
          return {
            ...schedule,
            sms_notification: SMS_STATUSES.PENDING,
          }
        }
        return schedule
      })
    },

    UPDATE_INFO_DAY_INTERVAL(state, action) {
      state.dayInterval = action.payload
    },

    REMOVE_EVENTS_TO_NOTIFY(state, action) {
      const appointmentId = action.payload
      state.eventsToSendNotification = state.eventsToSendNotification.filter(
        (id: number) => id !== appointmentId,
      )
    },

    UPDATE_INFO_DRAGGING(state, action) {
      state.isDragging = action.payload
    },

    CLEANUP_INFO_STATE(state) {
      state.activeTabKey = initialState.activeTabKey
      state.isFetching = initialState.isFetching
      state.isUpdating = initialState.isUpdating
      state.patients = initialState.patients
      state.patient_statuses = initialState.patient_statuses
      state.rooms = initialState.rooms
      state.room_statuses = initialState.room_statuses
      state.schedules = initialState.schedules
      state.skipPusherEvents = initialState.skipPusherEvents
      state.disabledIntervals = initialState.disabledIntervals
      state.eventsToSendNotification = initialState.eventsToSendNotification
    },
  },
})

export const {
  UPDATE_INFO_ROOMS,
  UPDATE_INFO_SCHEDULES,
  UPDATE_INFO_PATIENTS,
  UPDATE_INFO_PATIENTS_LANGUAGES,
  UPDATE_INFO_PATIENTS_CONSULTATION_STAFFS,
  UPDATE_INFO_DOCTORS_BUFFER_TIME,
  UPDATE_INFO_ROOM_STATUSES,
  INSERT_INFO_SCHEDULE,
  UPDATE_INFO_IS_FETCHING,
  UPDATE_INFO_IS_UPDATING,
  UPDATE_INFO_ACTIVE_TAB_KEY,
  REMOVE_INFO_SCHEDULE,
  UPDATE_INFO_SCHEDULE,
  UPDATE_INFO_PATIENT_STATUSES,
  UPDATE_INFO_SKIP_PUSHER_EVENTS,
  UPDATE_INFO_SCHEDULE_LOCATION,
  UPDATE_INFO_SCHEDULE_STATUS,
  UPDATE_INFO_EVENTS_TO_SEND_NOTIFICATION,
  UPDATE_INFO_ROOM_STATUS,
  UPDATE_INFO_DISABLED_INTERVALS,
  UPDATE_INFO_SEND_NOTIFICATIONS,
  UPDATE_INFO_DAY_INTERVAL,
  UPDATE_INFO_TIME_ZONE,
  INSERT_INFO_EVENTS_TO_SEND_NOTIFICATION,
  SET_IS_UPDATING_ROOM_STATUS,
  UPDATE_STATUSES_TO_PENDING,
  REMOVE_EVENTS_TO_NOTIFY,
  CLEANUP_INFO_STATE,
  UPDATE_INFO_DRAGGING,
  UPDATE_INFO_PATIENTS_SELECTION,
} = infoSlice.actions
