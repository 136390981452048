import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { useAuth } from 'app/providers/auth/AuthProvider'

import type { ILevels } from 'common/interfaces/ILevels'
import { useFetchLevelsQuery } from 'common/store/api/rootApi'

interface IProps {
  levels: ILevels
  isFetching: boolean
}

const SitePointsContext = createContext<IProps | null>(null)

export const SitePointsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()

  const { data: levels, isFetching } = useFetchLevelsQuery(null, {
    skip: !user,
  })

  const value = useMemo(() => ({ levels, isFetching }), [levels, isFetching])

  return <SitePointsContext.Provider value={value}>{children}</SitePointsContext.Provider>
}

export const useSitePoints = () => {
  return useContext(SitePointsContext) as IProps
}
