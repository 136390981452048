export enum INPUT_PLACEHOLDER {
  TYPE_HERE = 'Type here',
  SELECT_STATE = 'Select state',
  SEARCH_PROCEDURE = 'Search for procedure',
}

export enum INPUT_TYPES {
  TEXT = 'text',
  EMAIL = 'email',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  PASSWORD = 'password',
  NUMBER = 'number',
}
