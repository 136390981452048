import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

import type { IFaqQuestion } from 'features/Faq/interfaces/IFaq'

export const faqApi = createApi({
  reducerPath: REDUCER_CONSTANTS.FAQ,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchQuestions: build.query({
      query: () => ({
        url: '/faqs',
      }),
      transformResponse: ({ items }: { items: IFaqQuestion[] }) =>
        items.sort((a: IFaqQuestion, b: IFaqQuestion) => a.order - b.order),
    }),
  }),
})

export const { useFetchQuestionsQuery }: any = faqApi
