export enum FORM_CONTROL_TYPE {
  RADIO = 'radio',
  INPUT = 'input',
  PASSWORD_INPUT = 'passwordInput',
  SELECT = 'select',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  TEXTAREA = 'textarea',
  UPLOAD = 'upload',
  AUTOCOMPLETE = 'autocomplete',
  RECAPTCHA = 'recaptcha',
  TIME_PICKER_SLOTS = 'timePickerSlots',
  PHONE = 'phone',
}
