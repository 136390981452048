import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { REDUCER_CONSTANTS } from 'common/constants/reducerConstants'
import type { ILevels } from 'common/interfaces/ILevels'
import type { IOption } from 'common/interfaces/IOption'
import type { IOptionWithId } from 'common/interfaces/IOption'
import type { ISelector } from 'common/interfaces/ISelector'
import { baseQuery } from 'common/store/api/fetchBaseQuery'

export const rootApi = createApi({
  reducerPath: REDUCER_CONSTANTS.ROOT,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchStates: build.query<IOption[], void>({
      query: () => ({
        url: '/states',
      }),
      transformResponse: (data: { states: IOption[] }): IOption[] => data.states,
    }),
    fetchCountries: build.query<IOption[], void>({
      query: () => ({
        url: '/countries',
      }),
      transformResponse: (data: IOptionWithId[]): IOption[] =>
        data
          .map(({ name, id, code }: IOptionWithId) => ({
            code: id,
            name: `${code}, ${name}`,
          }))
          .sort((a: IOption) => (a.name.includes('United States') ? -1 : 0)),
    }),
    fetchSites: build.query<ISelector, void>({
      query: () => ({
        url: '/site-selector',
      }),
    }),
    fetchLevels: build.query<ILevels, void>({
      query: () => ({
        url: '/levels',
      }),
      providesTags: ['Levels'],
    }),
  }),
})

export const {
  useFetchSitesQuery,
  useFetchStatesQuery,
  useFetchCountriesQuery,
  useLazyFetchSitesQuery,
  useLazyFetchLevelsQuery,
  useFetchLevelsQuery,
} = rootApi
