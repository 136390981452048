import type { FC } from 'react'

import styles from './cartError.module.scss'

interface IProps {
  error: string | undefined
}

export const CartError: FC<IProps> = ({ error }) => {
  if (!error) return null
  return <p className={styles.parent}>{error}</p>
}
