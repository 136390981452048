import { UtilService } from 'common/services/utilService'

import type { IOptionSlot, IOptionSlotDay } from 'features/Option/interfaces/IOption'

const { structuredClone } = UtilService

const getUpdatedReservedDays = (days: IOptionSlotDay[], day: IOptionSlotDay): IOptionSlotDay[] => {
  let daysClone: IOptionSlotDay[] = structuredClone<IOptionSlotDay[]>(days)
  const dateIndex = daysClone.findIndex((rDay: IOptionSlotDay) => rDay.date === day.date)
  if (dateIndex === -1) daysClone = [...daysClone, day]
  else daysClone.splice(dateIndex, 1)
  return daysClone
}

const getReservedOption = (optionId: number, options: IOptionSlot[]): IOptionSlot | undefined =>
  options?.find(({ option }: IOptionSlot): boolean => option.id === optionId)

const getOptionIndex = (optionId: number, options: IOptionSlot[]): number =>
  options.findIndex(({ option: rOption }: IOptionSlot) => rOption.id === optionId)

const updateOptionsPrice = (options: IOptionSlot[]) =>
  options.map((rOption: IOptionSlot) => {
    const price = rOption.selectedDays.reduce((total, rDay) => total + rDay.price, 0)
    return {
      ...rOption,
      price,
    }
  })

const cleanupOptions = (options: IOptionSlot[]): IOptionSlot[] =>
  options.filter(({ selectedDays }: IOptionSlot) => !!selectedDays.length)

const getUpdatedOptions = (option: IOptionSlot, options: IOptionSlot[]): IOptionSlot[] => {
  let optionsClone: IOptionSlot[] = structuredClone<IOptionSlot[]>(options)
  const optionIndex: number = getOptionIndex(option.option.id, options)
  if (optionIndex === -1) optionsClone = [...optionsClone, option]
  else optionsClone.splice(optionIndex, 1, option)

  // Clean up the options structure
  optionsClone = cleanupOptions(optionsClone)

  return updateOptionsPrice(optionsClone)
}

const removeDayInOption = (
  date: string,
  optionId: number,
  options: IOptionSlot[],
): IOptionSlot[] => {
  const optionIndex = getOptionIndex(optionId, options)
  const reservedOption: IOptionSlot = structuredClone<IOptionSlot>(options.at(optionIndex))
  reservedOption.selectedDays = getUpdatedReservedDays(reservedOption.selectedDays, { date })
  return getUpdatedOptions(reservedOption, options)
}

const removeDateInOptions = (date: string, options: IOptionSlot[]): IOptionSlot[] => {
  let optionsClone: IOptionSlot[] = structuredClone<IOptionSlot[]>(options)
  optionsClone = optionsClone.map((option: IOptionSlot) => {
    const selectedDays = option.selectedDays.filter(
      (rDay: IOptionSlotDay): boolean => rDay.date !== date,
    )
    return { ...option, selectedDays }
  })
  optionsClone = cleanupOptions(optionsClone)
  return updateOptionsPrice(optionsClone)
}

export const OptionFixedService = {
  getUpdatedReservedDays,
  getReservedOption,
  getUpdatedOptions,
  removeDayInOption,
  removeDateInOptions,
}
