import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { StateService } from 'common/services/stateService'
import { rootSlice } from 'common/store/slices/rootSlice'

import { cartSlice } from 'features/Cart'
import { infoSlice } from 'features/Info'
import { leaseSlice } from 'features/Lease'
import { optionSlice } from 'features/Option'
import { promotionSlice } from 'features/Promotions/state/api/promotionsSlice'

const rootReducer = combineReducers({
  rootReducer: rootSlice.reducer,

  cartReducer: cartSlice.reducer,
  leaseReducer: leaseSlice.reducer,
  optionReducer: optionSlice.reducer,
  infoReducer: infoSlice.reducer,
  promotionReducer: promotionSlice.reducer,
  ...StateService.getAppApiReducerList(),
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(StateService.getAppMiddlewareList()),
})
