export enum BOOKING_STATUS {
  EMPTY = 'empty',
  QUARTER = 'quarter',
  HALF = 'half',
  THREE_QUARTERS = 'three-quarters',
  FULL = 'full',
}

export const BOOKING_STATUS_LABELS = {
  [BOOKING_STATUS.EMPTY]: 'empty',
  [BOOKING_STATUS.QUARTER]: '1/4 occupied',
  [BOOKING_STATUS.HALF]: '1/2 occupied',
  [BOOKING_STATUS.THREE_QUARTERS]: '3/4 occupied',
  [BOOKING_STATUS.FULL]: 'fully booked',
}

export const CALENDAR_LINK_TYPES = {
  GOOGLE: 'google',
  APPLE: 'apple',
}
