import type { ChangeEvent, FC } from 'react'

import { ErrorMessage, Field, useField } from 'formik'

import { PasswordInput } from 'common/components/Input/PasswordInput'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import type { IInput } from 'common/interfaces/IInput'

import styles from './formPassword.module.scss'

export const FormPassword: FC<IInput> = ({ name, onChange, ...rest }) => {
  const [field, _, helpers] = useField(name)

  return (
    <div className={styles.parent}>
      <Field
        {...rest}
        {...field}
        id={name}
        data-cy={`${name}-form-control`}
        component={PasswordInput}
        as={FORM_CONTROL_TYPE.PASSWORD_INPUT}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          helpers.setValue(event.target.value)
          if (onChange) onChange(event)
        }}
        className={styles.parentInputPassword}
        onBlur={() => field.value && helpers.setValue(field.value.trim().replace(/\s\s+/g, ' '))}
      />
      <ErrorMessage
        name={name}
        className={styles.parentErrorInform}
        render={(msg) => {
          return <p className={styles.parentErrorInform}>{msg}</p>
        }}
      />
    </div>
  )
}
