import type { FC, JSX } from 'react'
import { useCallback } from 'react'

import { useCartMonitor } from 'app/providers/index'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartOption } from 'features/Cart/interfaces/ICartOption'
import type { IOptionStaff } from 'features/Option'
import { SET_OPTION_STAFF_LIST } from 'features/Option'

export const CartStaff: FC<ICartOption> = ({ handleEdit, handleUpdate }) => {
  const { isUpdatingCart } = useCartMonitor()

  const dispatch = useAppDispatch()

  const { bookingCart } = useCartManager()
  const { optionStaffList: staffList } = useAppSelector((state) => state.optionReducer)

  const { staff = { info: {}, list: [], selected: [] } } = bookingCart?.data ?? {}

  const handleRemove = useCallback(
    (id: number): void => {
      const updatedSelected: number[] = staffList.filter(
        (staffId: number): boolean => staffId !== id,
      )
      dispatch(SET_OPTION_STAFF_LIST(updatedSelected))
      handleUpdate({
        staff: {
          ...staff,
          selected: updatedSelected,
        },
      })
    },
    [bookingCart, staff, isUpdatingCart, staffList],
  )

  if (!staff?.selected?.length || !staffList.length) return null

  return (
    <div className='cart-card'>
      <hr />
      <CartItem
        name={staff?.info?.name}
        handleEdit={() => handleEdit(staff.info)}
        img={staff.info?.images.at(0)}>
        {staff.list?.map((staffItem: IOptionStaff, index: number): JSX.Element => {
          const staffId: number = staffItem.code as number
          if (!staffList.includes(staffId)) return null
          return (
            <CartCollapse
              key={index}
              title={staffItem.name}
              handleRemoveSlot={() => handleRemove(staffId)}>
              {staffItem.qualification}
            </CartCollapse>
          )
        })}
      </CartItem>
    </div>
  )
}
