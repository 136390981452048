import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import type { InputRef } from 'antd'
import { Checkbox, Form, Input, Select } from 'antd'
import type { DefaultOptionType } from 'antd/lib/select'
import { format } from 'date-fns'

import { AutoComplete } from 'common/components/AutoComplete/AutoComplete'
import PhoneInput from 'common/components/PhoneInput/PhoneInput'
import { Show } from 'common/components/Show/Show'
import { TimePickerBySlots } from 'common/components/TimePickerBySlots/TimePickerBySlots'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { TABLE_COLUMN_TYPES } from 'common/constants/tableConstants'
import type { IEditableCell } from 'common/interfaces/ITable'

import 'antd/lib/time-picker/style/css'
import './time-picker.css'

export const EditableCell: FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  options,
  defaultValue,
  isValidField,
  disabled,
  setFormFieldValue,
  onFieldChange,
  errorMessage,
  ...restProps
}) => {
  const [open, setOpen] = useState(false)
  const [rules, setRules] = useState<any>([
    {
      required: dataIndex !== 'language',
      message: `Please input ${title} `,
    },
  ])
  const cellRef = useRef<InputRef>(null)

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false)
    }

    const modal = document.querySelector('.ant-table-body')
    const elementToCheck = modal || window

    elementToCheck.addEventListener('scroll', handleScroll)
    return () => {
      elementToCheck.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isValidField && rules.length === 1) {
      setRules([
        () => ({
          validator(_: any, value: any) {
            if (isValidField(value)) return Promise.resolve()
            return Promise.reject(
              new Error(
                errorMessage || `The input is not valid ${dataIndex?.replaceAll('_', ' ')}!`,
              ),
            )
          },
        }),
      ])
    }
  }, [])

  const cellNode = () => {
    switch (inputType) {
      case TABLE_COLUMN_TYPES.AUTOCOMPLETE:
        return (
          <AutoComplete
            disabled={disabled}
            options={options as DefaultOptionType[]}
            onChange={(value: string, option) => {
              if (option.hasOwnProperty('value'))
                onFieldChange(dataIndex, option, setFormFieldValue)
              else onFieldChange(dataIndex, value, setFormFieldValue)
            }}
          />
        )
      case TABLE_COLUMN_TYPES.CHECKBOX:
        return <Checkbox disabled={disabled} />
      case TABLE_COLUMN_TYPES.PHONE:
        return <PhoneInput border={false} disabled={disabled} />

      case TABLE_COLUMN_TYPES.SELECT:
        return (
          <Select
            open={open}
            defaultValue={defaultValue?.label}
            disabled={disabled}
            dropdownMatchSelectWidth={150}
            fieldNames={record?.key}
            className='table-update-select'
            optionFilterProp='children'
            options={options}
            onChange={(value: string) => {
              onFieldChange(dataIndex, value)
            }}
            onDropdownVisibleChange={(isOpen: boolean) => setOpen(isOpen)}
          />
        )
      case TABLE_COLUMN_TYPES.NUMBER:
        return <Input type={INPUT_TYPES.NUMBER} />
      case TABLE_COLUMN_TYPES.TIME_PICKER_SLOTS:
        return (
          <TimePickerBySlots
            options={options}
            index={dataIndex}
            disabled={disabled}
            onUpdateField={() => {}}
            onFieldChange={onFieldChange}
            date={format(new Date(), DATE_FORMAT.CALENDAR_DATE)}
          />
        )
      default:
        return (
          <Input
            disabled={disabled}
            onChange={() => {
              setFormFieldValue(dataIndex, cellRef.current.input.value.replace(/\s+/g, ' '))
            }}
            ref={cellRef}
            onBlur={() => {
              const inputValue: string = cellRef.current.input.value.trim().replace(/\s\s+/g, ' ')
              setFormFieldValue(dataIndex, inputValue)
            }}
          />
        )
    }
  }

  const valuePropName = () => {
    switch (inputType) {
      case TABLE_COLUMN_TYPES.CHECKBOX:
        return 'checked'
      default:
        return 'value'
    }
  }

  return (
    <td {...restProps}>
      <Show when={editing} fallback={children}>
        <Form.Item
          validateTrigger='onChange'
          valuePropName={valuePropName()}
          rules={rules}
          name={dataIndex}
          style={{ marginTop: '20px' }}>
          {cellNode()}
        </Form.Item>
      </Show>
    </td>
  )
}
