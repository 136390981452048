import type { FC, JSX } from 'react'

import { CartError } from 'features/Cart/components/CartError/CartError'

import styles from './cartErrorList.module.scss'

interface IProps {
  errorList?: string[]
}

export const CartErrorList: FC<IProps> = ({ errorList }) => {
  if (!errorList) return null
  return (
    <div className={styles.parent}>
      {errorList.map(
        (error: string, index: number): JSX.Element => (
          <CartError error={error} key={index} />
        ),
      )}
    </div>
  )
}
