import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import { Skeleton } from 'antd'

import { Show } from 'common/components/Show/Show'
import { TagStatus } from 'common/components/TagStatus/TagStatus'

import { SUPPORT_CONSTANTS } from 'features/Support/constants/supportConstants'
import type { ITicket } from 'features/Support/interfaces/ISupport'

import styles from './supportInfo.module.scss'

interface IProps {
  ticket: ITicket
  isLoading: boolean
}

export const SupportInfo: FC<IProps> = memo(({ ticket, isLoading }) => {
  const params = useParams()
  return (
    <section className={styles.parent}>
      <h2 className={styles.parentInfoTitle}>{SUPPORT_CONSTANTS.TICKET_INFO}</h2>
      <div className={styles.parentInfoDetails}>
        <h3 className={styles.parentDetailsTitle}>{SUPPORT_CONSTANTS.TICKET_ID}</h3>
        <div className={styles.parentStatus}>
          <p className={styles.parentDetailsDescription}>#{params?.ticketId}</p>
        </div>
        <TagStatus status={ticket?.status.name} />
      </div>
      <div className={styles.parentInfoDetails}>
        <h3 className={styles.parentDetailsTitle}>{SUPPORT_CONSTANTS.TICKET_CATEGORY}</h3>
        <Show when={!isLoading} fallback={<Skeleton paragraph={{ rows: 0 }} active />}>
          <p className={styles.parentDetailsDescription}>{ticket?.category.name}</p>
        </Show>
      </div>
      <div className={styles.parentInfoDetails}>
        <h3 className={styles.parentDetailsTitle}>{SUPPORT_CONSTANTS.TICKET_CREATED}</h3>
        <Show when={!isLoading} fallback={<Skeleton paragraph={{ rows: 0 }} active />}>
          <p className={styles.parentDetailsDescription}>{ticket?.created_at}</p>
        </Show>
      </div>
      <div className={styles.parentInfoDetails}>
        <h3 className={styles.parentDetailsTitle}>{SUPPORT_CONSTANTS.TICKET_UPDATED}</h3>
        <Show when={!isLoading} fallback={<Skeleton paragraph={{ rows: 0 }} active />}>
          <p className={styles.parentDetailsDescription}>{ticket?.last_update}</p>
        </Show>
      </div>
    </section>
  )
})
