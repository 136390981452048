import cartIcon from 'common/assets/icons/cart.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { useCartManager } from 'features/Cart/hooks/useCartManager'
import { TOGGLE_IS_OPEN_CART_DETAILS } from 'features/Cart/state/slices/cartSlice'

import styles from './cartIcon.module.scss'

export const CartIcon = () => {
  const dispatch = useAppDispatch()

  const { storageCart, mailboxCart, bookingCart, recurringCarts } = useCartManager()

  const { optionShopItems, optionTypeSlots, optionStaffList, optionTypeFixed } = useAppSelector(
    (state) => state.optionReducer,
  )
  const cartItems = (): number => {
    let total = 0
    const defaultArr = [
      optionShopItems?.length,
      bookingCart?.data?.rooms?.length,
      optionTypeSlots?.length,
      optionTypeFixed?.length,
      optionStaffList?.length ? 1 : 0,
      mailboxCart?.data ? 1 : 0,
      storageCart?.data ? 1 : 0,
      Object.keys(recurringCarts ?? {}).length,
    ]

    defaultArr.forEach((item) => {
      total += item || 0
    })

    return total
  }

  const handleOpenCartDetails = (): void => {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }

  return (
    <button onClick={handleOpenCartDetails} className={styles.parent} data-cy='cart-btn'>
      <span className={styles.parentNotification}>{cartItems()}</span>
      <img className={styles.parentIcon} src={cartIcon} alt={ALT_CONSTANTS.CART_ICON} />
    </button>
  )
}
