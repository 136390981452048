export enum APP_CONSTANTS {
  EDIT = 'Edit',
  DELETE = 'Delete',
  REMOVE = 'Remove',
  SEND_CODE = 'Send Code',
  INCLUDED = 'Included',
  STARTS = 'Starts',
  ENDS = 'Ends',
  RESET_CODE = 'Reset code',
  DOWNLOAD_CONTRACT = 'Download Contract',
  WELCOME = 'Welcome ',
  LOGIN_AS = 'Login as',
  LOGGED_IN_AS = 'Logged in as ',
  BACK = 'Back',
  DOCTOR = 'Doctor',
  DR = 'DR',
}
