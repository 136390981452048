import { useEffect, useLayoutEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Header from 'layout/Header/Header'
import Sidebar from 'layout/Sidebar/Sidebar'

import { useScreenWidth } from 'app'
import { withLocalStorageMonitor } from 'app/hocs'
import { useAuth, useNotification } from 'app/providers'

import { Show } from 'common/components/Show/Show'

import { Cart, useCartManager } from 'features/Cart'
import { useShopCartManager } from 'features/Option/hooks/useShopCartManager'

const AuthorizedRoutes = () => {
  const { pathname } = useLocation()
  const { user, isSidebarVisible, toggleIsSidebarVisible } = useAuth()
  const { isMobile } = useScreenWidth()

  const { fetchCartsData } = useCartManager()
  const { fetchShopCartData } = useShopCartManager()
  const { notification } = useNotification()

  useEffect(() => {
    fetchCartsData()
    fetchShopCartData()
  }, [])

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 })
  }, [pathname])

  if (pathname.includes('checkout'))
    return (
      <>
        {notification}
        <Outlet />
      </>
    )
  return (
    <>
      <Header />
      <Show when={!isMobile}>
        <Sidebar />
      </Show>
      <Show when={isMobile && isSidebarVisible}>
        <Sidebar
          isSidebarVisible={isSidebarVisible}
          toggleIsSidebarVisible={toggleIsSidebarVisible}
        />
      </Show>
      <div className='application-layout__content' data-guest={!user}>
        <div
          className='application-layout__content-container'
          onClick={() => isMobile && isSidebarVisible && toggleIsSidebarVisible()}>
          {notification}
          <Outlet />
          <Cart />
        </div>
      </div>
    </>
  )
}

export default withLocalStorageMonitor(AuthorizedRoutes)
