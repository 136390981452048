import type { ComponentType, FC } from 'react'

import { ConnectionProvider } from 'app/providers/connection/ConnectionProvider'
export const withConnection = (Component: ComponentType) => {
  const WithConnection: FC = (props) => {
    return (
      <ConnectionProvider>
        <Component {...props} />
      </ConnectionProvider>
    )
  }

  return WithConnection
}
