import { UtilService } from 'common/services/utilService'

import type { ILeaseInfoRoom, IRoomTimeSlot } from 'features/Lease/interfaces/ILeaseInfoRoom'
import type {
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
} from 'features/Lease/interfaces/ILeaseInfoRoom'
import type { IRoomOptionProduct } from 'features/Lease/interfaces/ILeaseInfoRoom'
import { OptionService } from 'features/Option/services/optionService'

const { structuredClone } = UtilService
const { getFilteredDaysByDate } = OptionService

const getDayInSelectedDays = (selectedDays: IRoomSelectedDay[], date: string): IRoomSelectedDay =>
  selectedDays.find((reservedDay: IRoomSelectedDay): boolean => reservedDay.date === date)

const getUpdatedRoom = (slotId: number, date: string, roomLeaseInfo: ILeaseInfoRoom) => {
  const copyRoomLeaseInfo: ILeaseInfoRoom = structuredClone<ILeaseInfoRoom>(roomLeaseInfo)
  const removeSlotDayInfo: IRoomSelectedDay = getDayInSelectedDays(
    copyRoomLeaseInfo.selectedDays,
    date,
  )
  if (removeSlotDayInfo.selectedSlots.length === 1)
    copyRoomLeaseInfo.selectedDays = getFilteredDaysByDate<IRoomSelectedDay>(
      copyRoomLeaseInfo.selectedDays,
      date,
    )
  else {
    const updatedSlots = removeSlotDayInfo.selectedSlots.filter(
      (reservedSlot: IRoomSelectedTimeSlot): boolean => {
        return reservedSlot.id !== slotId
      },
    )
    copyRoomLeaseInfo.selectedDay.selectedSlots = updatedSlots

    copyRoomLeaseInfo.selectedDays = copyRoomLeaseInfo.selectedDays.map(
      (reservedDay: IRoomSelectedDay): IRoomSelectedDay =>
        reservedDay.date === removeSlotDayInfo.date
          ? { ...removeSlotDayInfo, selectedSlots: updatedSlots }
          : reservedDay,
    )
  }
  return copyRoomLeaseInfo
}

const getUpdatedLease = (
  newLeaseInfo: ILeaseInfoRoom,
  newLeaseRooms: ILeaseInfoRoom[],
): ILeaseInfoRoom[] => {
  const { room, selectedDays } = newLeaseInfo
  let copyLeaseRooms: ILeaseInfoRoom[] = structuredClone<ILeaseInfoRoom[]>(newLeaseRooms)
  // The last room reserved for rent with the same id with the same new reservation
  const reservedLease: ILeaseInfoRoom | undefined = copyLeaseRooms
    .filter((reservedLease: ILeaseInfoRoom): boolean => reservedLease.room.id === room.id)
    .at(-1)
  if (reservedLease) {
    if (!selectedDays.length) {
      //If there are no more days in reservations for the room, delete the
      // room and change the order id of the other rooms with the same id
      copyLeaseRooms = copyLeaseRooms
        .filter(
          (leaseInfo: ILeaseInfoRoom): boolean =>
            leaseInfo.id !== newLeaseInfo.id || leaseInfo.room.id !== room.id,
        )
        .map(
          (leaseInfo: ILeaseInfoRoom): ILeaseInfoRoom =>
            leaseInfo.room.id === room.id && newLeaseInfo.id < leaseInfo.id
              ? { ...leaseInfo, id: leaseInfo.id - 1 }
              : leaseInfo,
        )
    } else if (!newLeaseInfo.id) copyLeaseRooms.push({ ...newLeaseInfo, id: reservedLease.id + 1 })
    else {
      // If it's just a change, replace the data with the new ones
      copyLeaseRooms = copyLeaseRooms.map(
        (reservedLease: ILeaseInfoRoom): ILeaseInfoRoom =>
          reservedLease.id === newLeaseInfo.id && reservedLease.room.id === room.id && reservedLease
            ? newLeaseInfo
            : reservedLease,
      )
    }
  } else copyLeaseRooms.push({ ...newLeaseInfo, id: 1 })
  return getUpdatedRoomsPrice(copyLeaseRooms)
}

const getLeaseRoomsPrice = (days: IRoomSelectedDay[]): number =>
  days.reduce((totalPrice: number, currentDate: IRoomSelectedDay) => {
    const currentDatePrice: number = getLeasePriceBySlots(currentDate?.selectedSlots)
    return totalPrice + currentDatePrice
  }, 0)

export const getLeasePriceBySlots = (slots: IRoomSelectedTimeSlot[]): number => {
  const price: number = slots.reduce((slotTotal: number, slot: IRoomSelectedTimeSlot) => {
    const slotPrice = slot.price
    const optionsPrice = slot.selectedOptions.reduce(
      (optionTotal: number, option: IRoomOptionProduct) => {
        return optionTotal + option.price * (option?.stock || 1)
      },
      0,
    )
    return slotTotal + slotPrice + optionsPrice
  }, 0)

  return price
}

const getRoomById = (rooms: ILeaseInfoRoom[], id: number): ILeaseInfoRoom =>
  rooms.find((room: ILeaseInfoRoom): boolean => room.id === id)

const getUpdatedRoomsPrice = (rooms: ILeaseInfoRoom[]): ILeaseInfoRoom[] =>
  rooms.map(
    (lease: ILeaseInfoRoom): ILeaseInfoRoom => ({
      ...lease,
      price: getLeaseRoomsPrice(lease.selectedDays),
    }),
  )

const updateSiteOptionsVisibility = (visible: boolean, options: IRoomOptionProduct[]) => {
  if (!Array.isArray(options)) return []
  const adjustedOptions: IRoomOptionProduct[] = options.map(
    (product: IRoomOptionProduct): IRoomOptionProduct => ({
      ...product,
      is_visible: !product.is_visible || visible ? 0 : 1,
    }),
  )

  return adjustedOptions
}

const getReservationSelectedSlot = (
  slots: IRoomSelectedTimeSlot[],
  slot: IRoomTimeSlot,
): IRoomSelectedTimeSlot | null => {
  if (slot) {
    const activeTimeSlot: IRoomSelectedTimeSlot | undefined = slots?.find(
      ({ id }: IRoomSelectedTimeSlot): boolean => id === slot.id,
    )
    if (activeTimeSlot) return activeTimeSlot
    else
      return {
        ...slot,
        selectedOptions: [],
      }
  }
  return null
}

export const LeaseService = {
  getRoomById,
  getUpdatedLease,
  getUpdatedRoom,
  getUpdatedRoomsPrice,
  getLeaseRoomsPrice,
  getDayInSelectedDays,
  getLeasePriceBySlots,
  getReservationSelectedSlot,
  updateSiteOptionsVisibility,
}
