import type { FC, JSX } from 'react'

import { Skeleton, Switch } from 'antd'
import { ErrorMessage, Field, useField } from 'formik'

import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'

import styles from './formSwitch.module.scss'

interface IProps {
  name: string
  label?: string | JSX.Element
  isLoading?: boolean
}

export const FormSwitch: FC<IProps> = ({ name, label, isLoading, ...rest }) => {
  const [field, _, helpers] = useField(name)
  return (
    <div className={styles.parent}>
      <label htmlFor={name} className={styles.parentLabel}>
        {label}
      </label>
      {!isLoading ? (
        <Field
          id={name}
          {...rest}
          {...field}
          onChange={(isSelected: boolean) => helpers.setValue(isSelected)}
          component={Switch}
          checked={field.value}
          data-cy={`${name}-form-control`}
          as={FORM_CONTROL_TYPE.INPUT}
        />
      ) : (
        <Skeleton.Button active />
      )}
      <ErrorMessage component='p' className={styles.parentErrorInform} name={name} />
    </div>
  )
}
