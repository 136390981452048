export enum UPLOAD_CONSTANTS {
  PREVIEW = 'Preview',
  SELECT_FILE = 'Select a file',
  DRAG_AND_DROP = ' or drag and drop here',
  FILE_DESCRIPTION = 'JPG, PNG or PDF, file size no more than 10MB',
  SIZE_ERROR_TITLE = 'File size limit',
  TYPE_ERROR_TITLE = 'Invalid file type',
  SIZE_ERROR_DESCRIPTION = 'Uploaded file size it’s bigger than the 10MB limit',
  TYPE_ERROR_DESCRIPTION = 'Uploaded file type it’s not supported',
}
