import type { FC } from 'react'
import Turnstile from 'react-turnstile'

import { ErrorMessage, Field, useField } from 'formik'

import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'

import styles from './formInput.module.scss'

interface IProps {
  name: string
}

export const FormRecaptcha: FC<IProps> = ({ name }) => {
  const [field, _, helpers] = useField(name)
  return (
    <div className={styles.parent}>
      <Field
        {...field}
        id={name}
        component={Turnstile}
        onVerify={helpers.setValue}
        onExpire={() => helpers.setValue(null)}
        refreshExpired='auto'
        size='normal'
        theme='light'
        retry='never'
        as={FORM_CONTROL_TYPE.INPUT}
        sitekey={process.env.REACT_APP_TURNSTILE_KEY}
        data-cy={`${name}-form-control`}
      />
      <ErrorMessage component='p' className={styles.parentErrorInform} name={name} />
    </div>
  )
}
