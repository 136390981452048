import type { JSX } from 'react'
import { Route, Routes as ReactRoutes } from 'react-router-dom'

import AuthorizedRoutes from 'routes/Authorized/AuthorizedRoutes'
import { useAppRoutes } from 'routes/hooks/useAppRoutes'
import { PATHS } from 'routes/paths'
import { UnauthorizedRoutes } from 'routes/Unauthorized/UnauthorizedRoutes'
import { UnconfirmedRoutes } from 'routes/Unconfirmed/UnconfirmedRoutes'

import GenericModal from 'common/components/GenericModal'
import Popup from 'common/components/Popup'

import { Generator } from 'features/Generator'

export const Routes = () => {
  const { unauthorized, unconfirmed, authorized } = useAppRoutes()

  return (
    <>
      <Popup />
      <GenericModal />
      <ReactRoutes>
        <Route element={<UnconfirmedRoutes />}>
          {unconfirmed.map(
            ({ element, path }): JSX.Element => (
              <Route key={path} path={path} element={element} />
            ),
          )}
        </Route>
        <Route element={<AuthorizedRoutes />}>
          {authorized.map(
            ({ element, path }): JSX.Element => (
              <Route key={path} path={path} element={element} />
            ),
          )}
        </Route>
        <Route element={<UnauthorizedRoutes />}>
          {unauthorized.map(
            ({ element, path }): JSX.Element => (
              <Route key={path} path={path} element={element} />
            ),
          )}
        </Route>
        <Route path={PATHS.GENERATOR} element={<Generator />} />
      </ReactRoutes>
    </>
  )
}
