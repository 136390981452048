import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { PATHS } from 'routes/paths'

import { useAuth, useNotification } from 'app/providers'

import type { IRequestResponse } from 'common/interfaces/IRequestResponse'

import { useFetchValidationInfoQuery } from 'features/Auth'
import type { IAuthValidation } from 'features/Auth/interfaces/IAuth'
import type { IUploadedValidationDocument } from 'features/Auth/interfaces/IAuthUploadedDocument'

export const UnconfirmedRoutes = () => {
  const { user, updateDocuments, hasCompletedDocuments } = useAuth()
  const { notification } = useNotification()

  const { data: validationInfo }: IRequestResponse<IAuthValidation> = useFetchValidationInfoQuery({
    skip: !user,
  })

  const { documents = [] } = validationInfo ?? {}

  useEffect(() => {
    if (documents) {
      const validatedDocuments = documents.filter(
        (uploadedDocument: IUploadedValidationDocument): boolean =>
          Boolean(uploadedDocument.is_valid),
      )
      const justUploadedDocuments = documents.filter(
        (uploadedDocument: IUploadedValidationDocument): boolean =>
          uploadedDocument.is_valid === null,
      )

      if (validatedDocuments.length === 2 && !justUploadedDocuments.length)
        return updateDocuments(documents)
    }
  }, [documents])

  if (user && hasCompletedDocuments) return <Navigate to={PATHS.HOME_ONGOING} />

  return (
    <>
      {notification}
      <Outlet />
    </>
  )
}
