import type { FC, JSX } from 'react'
import { useEffect, useState } from 'react'

import { LeaseSlot } from 'features/Lease/components/LeaseSlot/LeaseSlot'
import { LeaseSlotSkeletonList } from 'features/Lease/components/LeaseSlotSkeletonList/LeaseSlotSkeletonList'
import type { IRoomTimeSlot } from 'features/Lease/interfaces/ILeaseInfoRoom'
import type { ILeaseSlotListProps } from 'features/Lease/interfaces/ILeaseSlotListProps'

import styles from './leaseSlotList.module.scss'

export const LeaseSlotList: FC<ILeaseSlotListProps> = ({
  slots,
  isFetching,
  currentSelected,
  selectedSlots,
  handleRemoveTimeSlot,
  handleSelectActiveSlot = () => undefined,
  handleConfirmActiveSlot,
}) => {
  const [confirmedSlot, setConfirmedSlot] = useState<IRoomTimeSlot | null>(null)

  const handleConfirmSlot = (slot: IRoomTimeSlot): void => {
    setConfirmedSlot(slot)
    handleConfirmActiveSlot(slot)
  }

  useEffect(() => {
    if (confirmedSlot) setConfirmedSlot(null)
  }, [selectedSlots])

  const handleChangeActiveSlot = (slot: IRoomTimeSlot): void => {
    if (confirmedSlot) setConfirmedSlot(null)
    handleSelectActiveSlot(slot)
  }

  if (isFetching)
    return (
      <div className={styles.parent}>
        <LeaseSlotSkeletonList />
      </div>
    )

  return (
    <div className={styles.parent}>
      {slots?.map((roomSlot: IRoomTimeSlot): JSX.Element => {
        return (
          <LeaseSlot
            slot={roomSlot}
            key={roomSlot.id}
            currentSelected={currentSelected}
            handleConfirmSlot={handleConfirmSlot}
            handleRemoveTimeSlot={handleRemoveTimeSlot}
            handleSelectActiveSlot={handleChangeActiveSlot}
            isConfirmedSlot={roomSlot?.id === confirmedSlot?.id}
            isSelectedSlot={selectedSlots.some(
              (selectedSlot: IRoomTimeSlot) => selectedSlot.id === roomSlot.id,
            )}
          />
        )
      })}
    </div>
  )
}
