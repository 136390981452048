import type { FC, JSX } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { AnimatePresence, motion } from 'framer-motion'

import closeIcon from 'common/assets/icons/close-outline.svg'

import { Button } from 'common/components/Button/Button'
import { ALT_CONSTANTS } from 'common/constants/altConstants'

import styles from './overlay.module.scss'

interface IProps {
  title: string
  isOpen: boolean
  onClose: () => void
  children: JSX.Element
}

const Overlay: FC<IProps> = ({ children, isOpen, onClose, title }) => {
  const overlayRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) window.document.body.style.overflow = 'hidden'

    return (): void => {
      if (isOpen) window.document.body.style.overflow = ''
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    const bodyStyle = document.querySelector('body')?.getAttribute('style')
    if (bodyStyle) {
      onClose()
    }
  }, [isOpen, onClose])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          onClick={handleClose}
          className={styles.parent}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <motion.div
            onClick={(event) => event.stopPropagation()}
            ref={overlayRef}
            className={styles.parentContent}
            initial={{ x: '100%', opacity: 0.5 }}
            animate={{ x: 0, opacity: 1, position: 'relative' }}
            exit={{ x: '100%', opacity: 0.5 }}
            transition={{ type: 'spring', stiffness: 120, damping: 20 }}>
            <div className={styles.parentHeader}>
              <div className={styles.parentHeaderContent}>
                <h3 className={styles.parentTitle}>{title}</h3>
                <Button className='reset-button' onClick={handleClose}>
                  <img
                    src={closeIcon}
                    alt={ALT_CONSTANTS.ACTION_ICON}
                    className={styles.parentCloseIcon}
                  />
                </Button>
              </div>
              <hr className={styles.parentHeaderSeperator} />
            </div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Overlay
