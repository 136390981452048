import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { UtilService } from 'common/services/utilService'

import type { IOptionSelectFixed } from 'features/Option/interfaces/IOption'
import type { IOptionSelectFixedRoom } from 'features/Option/interfaces/IOption'

const { structuredClone } = UtilService

const getSelectedOption = (
  optionId: number,
  options: IOptionSelectFixed[],
): IOptionSelectFixed | undefined =>
  options.find(({ option }: IOptionSelectFixed) => option.id === optionId)

const getSelectedRoomsByListCode = (
  selectedList: string[],
  options: IOptionSelectFixedRoom[],
): IOptionSelectFixedRoom[] =>
  options.filter(({ code }: IOptionSelectFixedRoom) => selectedList.includes(code))

const getSelectedRoomsPrice = (rooms: IOptionSelectFixedRoom[]): number =>
  rooms.reduce((total, { price }) => total + price, 0)

const cleanupListOptions = (options: IOptionSelectFixed[]) =>
  options.filter(({ selectedRooms }: IOptionSelectFixed): boolean => !!selectedRooms.length)

const getUpdatedSelectFixedList = (
  option: ISiteRoom,
  selectedRooms: IOptionSelectFixedRoom[],
  selectFixedList: IOptionSelectFixed[],
): IOptionSelectFixed[] => {
  let fixedListClone: IOptionSelectFixed[] = structuredClone<IOptionSelectFixed[]>(selectFixedList)

  const price = getSelectedRoomsPrice(selectedRooms)
  const updatedOption = { option, selectedRooms, price }

  const optionIndex = selectFixedList.findIndex(({ option: rOption }) => rOption.id === option.id)
  if (optionIndex === -1) fixedListClone = [...fixedListClone, updatedOption]
  else fixedListClone.splice(optionIndex, 1, updatedOption)

  // Clean up the option structure
  return cleanupListOptions(fixedListClone)
}

const getOptionSelectedRooms = (optionId: number, options: IOptionSelectFixed[]) => {
  const option = getSelectedOption(optionId, options)
  if (!option) return []
  return option.selectedRooms.map(({ code }) => code)
}

const removeRoomInOption = (roomCode: string, optionId: number, options: IOptionSelectFixed[]) => {
  let optionsClone: IOptionSelectFixed[] = structuredClone<IOptionSelectFixed[]>(options)
  const rOption: IOptionSelectFixed = structuredClone<IOptionSelectFixed>(
    getSelectedOption(optionId, options),
  )
  rOption.selectedRooms = rOption.selectedRooms.filter(
    (rRoom: IOptionSelectFixedRoom): boolean => rRoom.code !== roomCode,
  )
  optionsClone = optionsClone.map((reservedOption: IOptionSelectFixed): IOptionSelectFixed => {
    if (reservedOption.option.id === optionId)
      return { ...rOption, price: getSelectedRoomsPrice(rOption.selectedRooms) }
    else return reservedOption
  })

  // Clean up the option structure
  return cleanupListOptions(optionsClone)
}

const invalidateRoomInterval = (
  alias: string,
  date: string,
  options: IOptionSelectFixed[],
): IOptionSelectFixed[] => {
  let optionsClone: IOptionSelectFixed[] = structuredClone<IOptionSelectFixed[]>(options)

  optionsClone = optionsClone.map((option: IOptionSelectFixed) => ({
    ...option,
    selectedRooms: option.selectedRooms.filter(
      ({ name, start_date, end_date }: IOptionSelectFixedRoom) =>
        !(name.includes(alias) && [start_date, end_date].includes(date)),
    ),
  }))

  // Clean up the option structure
  return cleanupListOptions(optionsClone)
}

const calculateActivePrice = (selected: string[], rooms: IOptionSelectFixedRoom[]): number => {
  const selectedRooms: IOptionSelectFixedRoom[] = getSelectedRoomsByListCode(
    selected as string[],
    rooms,
  )

  return getSelectedRoomsPrice(selectedRooms)
}

export const OptionSelectFixedService = {
  getSelectedOption,
  removeRoomInOption,
  getSelectedRoomsByListCode,
  getUpdatedSelectFixedList,
  getOptionSelectedRooms,
  invalidateRoomInterval,
  getSelectedRoomsPrice,
  calculateActivePrice,
}
