import type { FC } from 'react'
import { memo } from 'react'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import styles from './userPhoto.module.scss'

interface IProps {
  src?: string
  initials?: string
  hasShadow?: boolean
}

export const WidgetPhoto: FC<IProps> = memo(({ initials, src, hasShadow = true }) => {
  return (
    <>
      {src ? (
        <img src={src} alt={ALT_CONSTANTS.PROFILE} className={styles.image} />
      ) : (
        <div className={styles.parent} data-shadow={hasShadow}>
          {initials}
        </div>
      )}
    </>
  )
})
